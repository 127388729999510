import { React, useEffect } from 'react'
import { Row, Col, Container, Button } from 'react-bootstrap'
import { adminOrderList } from '../actions/orderActions'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import Loader from '../components/Loader'
import Message from '../components/Message'
import AdminNav from '../components/AdminNav'
import DayProgressBar from '../components/Admin/DayProgressBar'
import { logout } from '../actions/userActions'

export const AdminDashboardScreen = ({ history }) => {
  const orderStatsRes = useSelector((state) => state.adminOrderList)
  const { loading, error, data, orderStats } = orderStatsRes

  const dispatch = useDispatch()
  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(adminOrderList())
    if (error) {
      dispatch(logout())
      history.push('/login')
    }
  }, [dispatch])
  return (
    <Container>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message>error</Message>
      ) : (
        <Container>
          <AdminNav></AdminNav>

          <Container>
            {orderStats &&
              orderStats.map((stats) => (
                <>
                  <Row className='order-row' style={{ fontSize: '18px' }}>
                    <h2>{stats._id.createdAt}</h2>
                    <DayProgressBar
                      ProgressValue={stats.fullfilledOrder / stats.count}
                    ></DayProgressBar>
                    <Col>
                      COD:{stats.CODOrders}
                      <br />
                      <Link
                        to={`/admin/orderlist?date=${stats._id.createdAt}&filter=unconfirmed`}
                      >
                        <Button variant='outline-primary'>
                          UnConfirmed Order
                        </Button>
                      </Link>
                    </Col>
                    <Col>
                      # Box: {stats.numberOfBox}
                      {/* <br /># AI Box: {stats.numberOfAIBox} */}
                      <br />
                      <Link
                        to={`/admin/orderlist?date=${stats._id.createdAt}&filter=confirmed`}
                      >
                        <Button variant='outline-primary'>
                          Confirmed Order
                        </Button>
                      </Link>
                      <br />
                      <Link
                        to={`/admin/orderlist?date=${stats._id.createdAt}&filter=cunfulfilled`}
                      >
                        <Button variant='outline-primary'>
                          Confirmed UnFulfilled
                        </Button>
                      </Link>
                    </Col>
                    <Col>
                      Paid:{stats.paidOrders}
                      <br />
                      <Link
                        to={`/admin/orderlist?date=${stats._id.createdAt}&filter=paid`}
                      >
                        <Button variant='outline-primary'>Paid Orders</Button>
                      </Link>
                      <br />
                      <Link
                        to={`/admin/orderlist?date=${stats._id.createdAt}&filter=punfulfilled`}
                      >
                        <Button variant='outline-primary'>
                          Paid UnFulfilled
                        </Button>
                      </Link>
                    </Col>
                    <Col>
                      Orders: {stats.fullfilledOrder}/{stats.count}
                      <br />
                      <Link to={`/admin/orderlist?date=${stats._id.createdAt}`}>
                        <Button variant='outline-primary'>All Orders</Button>
                      </Link>
                    </Col>
                  </Row>
                  <br />
                </>
              ))}
          </Container>
        </Container>
      )}
    </Container>
  )
}
