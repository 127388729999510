import React, { useState, useEffect, useRef } from 'react'
import { Form, Button, Card, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import { saveShippingAddress } from '../actions/cartActions'
import CheckoutSteps from '../components/CheckoutSteps'
import Cookies from 'universal-cookie'
import { SHIPPING_STATE } from '../constants/orderConstants'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import OrderSummary from '../components/OrderSummary'
import { Link } from 'react-router-dom'
import { userAddressList, deleteUserAddress } from '../actions/userActions'
import Loader from '../components/Loader'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
const cookies = new Cookies()

const AddressSelectionScreen = ({ history }) => {
  const firstRender = useRef(true)
  const dispatch = useDispatch()
  const cart = useSelector((state) => state.cart)
  const { shippingAddress, loading, cartItems, cartSuccess } = cart

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userAddressListData = useSelector((state) => state.userAddressList)
  const {
    addressList,
    loading: userAddressListLoading,
    success: userAddressListSucess,
  } = userAddressListData

  const deleteUserAddressData = useSelector((state) => state.userAddressDelete)
  const { success: userAddressDeleteSucess } = deleteUserAddressData
  let cEmail = ' '
  let cName = ' '
  if (userInfo) {
    cEmail = userInfo.email
    cName = userInfo.name
  } else {
    cEmail = shippingAddress ? shippingAddress.email : ' '
    cName = shippingAddress ? shippingAddress.name : ''
  }

  const [email, setEmail] = useState(cEmail)
  const [name, setName] = useState(cName)
  const [address, setAddress] = useState(shippingAddress.address)
  const [address2, setAddress2] = useState(shippingAddress.address2)
  const [city, setCity] = useState(shippingAddress.city)
  const [postalCode, setPostalCode] = useState(shippingAddress.postalCode)
  const [phone, setPhone] = useState(shippingAddress.phone)
  const [state, setState] = useState(shippingAddress.state)
  const [disable, setDisabled] = useState(false)
  const [selectedAddress, setSelectedAddress] = useState({})
  const [emailError, setEmailError] = useState(null)
  const [phoneError, setPhoneError] = useState(null)
  const [zipError, setZipError] = useState(null)

  const [addNew, setAddNew] = useState(false)

  // const [country, setCountry] = useState(shippingAddress.country)

  let country = 'India'

  useEffect(() => {
    if (!userInfo) {
      history.push('/login?redirect=/checkout/basic-information')
    }
    window.scrollTo(0, 0)
    if (!userAddressListSucess) {
      dispatch(userAddressList())
    }

    if (firstRender.current) {
      firstRender.current = false
      return
    }
    if (userAddressListSucess) {
      if (addressList.length == 0) {
        setAddNew(true)
      }
      // if (addressList.length == 1 && addressList[0].zipcode !== '') {
      //   selectShippingAddressHandler(addressList[0])
      // }
    }
    setDisabled(formValidation())
  }, [
    dispatch,
    loading,
    userAddressDeleteSucess,
    addNew,
    userAddressListSucess,
  ])

  const formValidation = () => {
    if (email.length > 4) {
      setEmailError(null)
      return false
    }
  }
  const deleteUserAddressById = (addressId) => {
    dispatch(deleteUserAddress(addressId))
  }

  const selectShippingAddressHandler = (address) => {
    dispatch(
      saveShippingAddress(
        {
          name: address.name,
          address: address.address,
          address2: address.address2,
          city: address.city,
          postalCode: address.zipcode,
          state: address.state,
          country: 'IN',
          phone: address.phone,
          email: userInfo.email,
          guest: userInfo ? false : true,
        },
        true
      )
    )

    setTimeout(() => {
      history.push('/checkout/shipping')
    }, 1000)
  }
  function isEmail(email) {
    var emailFormat = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
    if (email !== '' && email.match(emailFormat)) {
      return true
    }

    return false
  }
  const submitHandler = (e) => {
    e.preventDefault()
    if (isEmail(email)) {
      setEmailError('')
      var phonenoExp = /^\d{10}$/
      if (phonenoExp.test(phone)) {
        setPhoneError('')
        if (postalCode.length == 6) {
          setZipError('')

          dispatch(
            saveShippingAddress({
              name,
              address,
              address2,
              city,
              postalCode,
              state,
              country,
              phone,
              email,
              guest: userInfo ? false : true,
            })
          )
          setTimeout(() => {
            history.push('/checkout/shipping')
          }, 1000)
        } else {
          setZipError('Enter Valid ZipCode')
        }
      } else {
        setPhoneError('Enter Valid Phone Number')
      }
    } else {
      setEmailError('Enter Valid email')
    }
  }
  // const submitHandler = (e) => {
  //   e.preventDefault()
  //   var phonenoExp = /^\d{10}$/
  //   if (phonenoExp.test(phone)) {
  //     dispatch(
  //       saveShippingAddress({
  //         name,
  //         address,
  //         address2,
  //         city,
  //         postalCode,
  //         state,
  //         country,
  //         phone: phone,
  //         email,
  //         guest: userInfo ? false : true,
  //       })
  //     )
  //     setTimeout(() => {
  //       history.push('/checkout/shipping')
  //     }, 1000)
  //   } else {
  //     setPhoneError('Enter Valid Phone Number')
  //   }
  // }
  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay))
  }
  return (
    <Container>
      <FormContainer>
        {/* <OrderSummary></OrderSummary> */}
        {/* <CheckoutSteps step1 /> */}
        <Row>
          <Col>
            <Link to={'/'}>
              <LazyLoadImage
                src='../../images/logo.png'
                className='checkout-logo'
                alt='99bookscart logo'
              ></LazyLoadImage>
            </Link>
          </Col>
          <Col>
            {' '}
            <div
              style={{
                float: 'right',
                marginTop: '14px',
                fontSize: '12px',
                fontWeight: 'bold',
                color: '#333',
              }}
            >
              +91901-915-8183
              <br />
              Mon-Sat 9:30am-6:30pm
            </div>
          </Col>
        </Row>
        <h1 className='cart-header'>Add Delivery Address</h1>
        <br />
        {!userInfo || addNew ? (
          <>
            <Form onSubmit={submitHandler} className='cart-form'>
              <Form.Group controlId='guestemail'>
                <Form.Text className='text-muted'>
                  {emailError && <span>{emailError}</span>}
                </Form.Text>
                {addNew ? (
                  <>
                    <Form.Control
                      type='hidden'
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    ></Form.Control>
                  </>
                ) : (
                  <>
                    <Form.Control
                      type='text'
                      required
                      placeholder='Enter Email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    ></Form.Control>
                    <small>Already have an Account?</small>{' '}
                    <Link to='/login'>Login</Link>
                  </>
                )}
              </Form.Group>
              <TextField
                id='outlined-basic'
                label='Full Name'
                variant='outlined'
                required
                type='Text'
                autoComplete='off'
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{ width: '100%', marginBottom: '10px' }}
              />

              <TextField
                id='outlined-basic'
                label='Address'
                variant='outlined'
                required
                type='Text'
                autoComplete='off'
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                style={{ width: '100%', marginBottom: '10px' }}
              />
              <TextField
                id='outlined-basic'
                label='Apartment, Suite, etc (Optional)'
                variant='outlined'
                type='Text'
                autoComplete='off'
                value={address2}
                onChange={(e) => setAddress2(e.target.value)}
                style={{ width: '100%', marginBottom: '10px' }}
              />
              <TextField
                id='outlined-basic'
                label='City'
                variant='outlined'
                type='Text'
                required
                autoComplete='off'
                value={city}
                onChange={(e) => setCity(e.target.value)}
                style={{ width: '48%', marginBottom: '10px' }}
              />
              <TextField
                id='outlined-basic'
                label='Zip Code'
                variant='outlined'
                type='Number'
                required
                autoComplete='off'
                error={zipError ? true : false}
                helperText={zipError ? zipError : ''}
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
                style={{
                  width: '48%',
                  marginBottom: '10px',
                  marginLeft: '10px',
                }}
              />

              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>State</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  required
                  label='State'
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                >
                  {SHIPPING_STATE.map((state) => (
                    <MenuItem value={state} key={state}>
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                id='outlined-basic'
                label='Phone Number'
                variant='outlined'
                type='phone'
                margin='normal'
                autoComplete='off'
                error={phoneError ? true : false}
                required
                helperText={phoneError ? phoneError : ''}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                style={{ width: '100%' }}
              />
              <br />
              <Button
                type='submit'
                variant='primary'
                disabled={disable}
                className='float-right'
              >
                Continue
              </Button>
            </Form>
            <br />
          </>
        ) : (
          <>
            {!userAddressListSucess ? (
              <Loader></Loader>
            ) : (
              <>
                <Row>
                  {addressList.length > 0 && (
                    <small className='text-center'>
                      Click on address to select
                    </small>
                  )}
                  {addressList.map((address) => (
                    <Col xs={12} key={address._id}>
                      <Card className=' rounded py-1' key={address._id}>
                        <Card.Body>
                          <Card.Text
                            onClick={() =>
                              selectShippingAddressHandler(address)
                            }
                            className='text-pointer'
                          >
                            <span>
                              {address.name}
                              <br />
                              {address.address},{address.address1}
                              <br />
                              {address.city},{address.state},<br />
                              {address.zipcode}
                              <br />
                              {address.phone}
                            </span>
                          </Card.Text>
                          {/* <Button
                            variant='danger'
                            className='btn-sm mr-auto float-right'
                            onClick={() => deleteUserAddressById(address._id)}
                          >
                            <i className='fas fa-trash-alt '></i> Remove
                          </Button> */}
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
                <Row>
                  <Button
                    type='button'
                    variant='primary'
                    className='float-right'
                    onClick={(e) => setAddNew(true)}
                    style={{
                      width: '80%',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  >
                    Add New Address
                  </Button>
                </Row>
              </>
            )}
          </>
        )}
      </FormContainer>
    </Container>
  )
}

export default AddressSelectionScreen
