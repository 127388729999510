import axios from 'axios'
import {
  CART_ADD_ITEM,
  CART_SAVE_SHIPPING_ADDRESS,
  RECENT_VIEW_ADD_ITEM,
  ABANDON_CART_FAIL,
  ABANDON_CART_LIST_REQUEST,
  ABANDON_CART_LIST_SUCCESS,
  ABANDON_CART_LIST_FAIL,
  CART_SAVE_DISCOUNT,
  EMAIL_POP_UP_SUCCESS,
  EMAIL_SUBMIT_REQUEST,
  EMAIL_SUBMIT_SUCCESS,
  EMAIL_SUBMIT_FAIL,
  EMAIL_SUBMIT_LIST_REQUEST,
  EMAIL_SUBMIT_LIST_SUCCESS,
  EMAIL_SUBMIT_LIST_FAIL,
  ABANDON_CART_DETAILS_REQUEST,
  ABANDON_CART_DETAILS_SUCCESS,
  ABANDON_CART_DETAILS_FAIL,
  USER_CART_REQUEST,
  USER_CART_SUCCESS,
  USER_CART_FAIL,
  USER_CART_REMOVE_REQUEST,
  USER_CART_REMOVE_SUCCESS,
  USER_CART_REMOVE_FAIL,
  DISCOUNT_CODE_REMOVE_SUCCESS,
  DISCOUNT_CODE_REMOVE_FAIL,
  USER_CART_PAYMENT_REQUEST,
  USER_CART_PAYMENT_SUCCESS,
  USER_CART_PAYMENT_FAIL,
  CART_LIST_REQUEST,
  CART_LIST_SUCCESS,
  CART_LIST_FAIL,
  USER_CART_EMAIL_SAVE_REQUEST,
  USER_CART_EMAIL_SAVE_SUCCESS,
  USER_CART_EMAIL_SAVE_FAIL,
  CART_UPDATE_REQUEST,
  CART_UPDATE_SUCCESS,
  CART_UPDATE_FAIL,
  USER_CART_EDIT_REQUEST,
  USER_CART_EDIT_SUCCESS,
  USER_CART_EDIT_FAIL,
} from '../constants/cartConstants'
import {
  DISCOUNT_CODE_FAIL,
  DISCOUNT_CODE_REQUEST,
  DISCOUNT_CODE_SUCCESS,
} from '../constants/discountCodeConstants'
import crypto from 'crypto'
import Cookies from 'universal-cookie'
const cookies = new Cookies()
let gConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
}
export const updateCartData = (data) => async (dispatch, getState) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    dispatch({
      type: CART_UPDATE_REQUEST,
    })
    const abandonCart = {
      id: cookies.get('_cid'),
      data: data,
    }
    const { data1 } = await axios.post(
      `/api/abandoncart/updateCartData`,
      abandonCart,
      config
    )
    dispatch({
      type: CART_UPDATE_SUCCESS,
      payload: data1,
    })
  } catch (error) {
    dispatch({
      type: CART_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const addToCart =
  (id, qty, variantId = 0, inventoryId, boxtype = 1, reset = false) =>
  async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState()

    var cartId
    if (userInfo && userInfo.cart) {
      cartId = userInfo.cart
    }
    cartId = cookies.get('_cid')
    const cart = {
      id: cartId,
      itemId: id,
      varaintId: variantId,
      inventoryId: inventoryId,
      boxtype: boxtype,
      reset: reset,
    }
    try {
      const { data } = await axios.post(
        `/api/abandoncart/addItem`,
        cart,
        gConfig
      )
      dispatch({
        type: CART_ADD_ITEM,
        payload: data.payload,
      })

      cookies.set('_cid', data.cart._id, {
        path: '/',
        maxAge: 34550000,
      })
    } catch (error) {
      dispatch({
        type: ABANDON_CART_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getCartDetailsFromStorage = () => async (dispatch, getState) => {
  // const {
  //   userLogin: { userInfo },
  // } = getState()
  var cartId = cookies.get('_cid')
  // if (userInfo && userInfo.cart) {
  //   cartId = userInfo.cart
  // }
  try {
    if (cartId !== undefined) {
      dispatch({
        type: USER_CART_REQUEST,
      })

      const { data } = await axios.get(`/api/abandoncart/${cartId}`, gConfig)

      dispatch({
        type: USER_CART_SUCCESS,
        payload: data,
      })
    } else {
      var empty = {
        empty: true,
      }
      dispatch({
        type: USER_CART_SUCCESS,
        payload: empty,
      })
    }
  } catch (error) {
    dispatch({
      type: USER_CART_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const recentlyViewed = (id) => async (dispatch, getState) => {
  const { data } = await axios.get(`/api/products/${id}`)

  dispatch({
    type: RECENT_VIEW_ADD_ITEM,
    payload: {
      product: data._id,
      _id: data._id,
      name: data.name,
      image: data.image,
      price: data.price,
      countInStock: data.countInStock,
      rating: data.rating,
      numReviews: data.numReviews,
      salePrice: data.salePrice,
      author: data.author,
      isbn: data.isbn,
      isBookscartBox: data.isBookscartBox,
      isBox: data.isBox,
    },
  })

  localStorage.setItem('recentView', JSON.stringify(getState().cart.recentView))
}

export const removeFromCart = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_CART_REMOVE_REQUEST,
      payload: id,
    })
    const cart = {
      id: cookies.get('_cid'),
      itemId: id,
    }
    const { data } = await axios.post(
      `/api/abandoncart/removeItem`,
      cart,
      gConfig
    )
    dispatch({
      type: USER_CART_REMOVE_SUCCESS,
      payload: data.payload,
    })
  } catch (error) {
    dispatch({
      type: USER_CART_REMOVE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const saveShippingAddress =
  (data, selected = false) =>
  async (dispatch, getState) => {
    dispatch({
      type: CART_SAVE_SHIPPING_ADDRESS,
      payload: data,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    let config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    if (!selected) {
      if (userInfo) {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        }

        await axios.post(`/api/users/shippingAdress/add`, data, config)
      }
    }

    const abandonCart = {
      id: cookies.get('_cid'),
      shippingAddress: data,
      user: userInfo ? userInfo : null,
    }
    try {
      await axios.post(`/api/abandoncart/addShipping`, abandonCart, config)

      var data11 = {
        data: [
          {
            event_name: 'AddPaymentInfo',
            event_time: Math.round(+new Date() / 1000),
            action_source: 'email',
            user_data: {
              em: [
                crypto
                  .createHash('sha256')
                  .update(userInfo.email.toLowerCase())
                  .digest('hex'),
              ],
            },
            custom_data: {
              currency: 'INR',
              value: localStorage.getItem('cartPrice'),
            },
          },
        ],
      }

      axios
        .post(process.env.FACEBOOK_CAPI, JSON.stringify(data11), config)
        .then(function (response) {})
        .catch(function (error) {})
    } catch (error) {
      dispatch({
        type: ABANDON_CART_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const saveDiscountCode = (name) => async (dispatch, getState) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    dispatch({
      type: DISCOUNT_CODE_REQUEST,
    })
    const abandonCart = {
      id: cookies.get('_cid'),
      discount: name,
    }
    const { data } = await axios.post(
      `/api/abandoncart/addDiscount`,
      abandonCart,
      config
    )
    dispatch({
      type: DISCOUNT_CODE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: DISCOUNT_CODE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const removeDiscountFromCart = () => async (dispatch) => {
  try {
    dispatch({
      type: DISCOUNT_CODE_REMOVE_SUCCESS,
    })
    const abandonCart = {
      id: cookies.get('_cid'),
    }
    let config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const { data } = await axios.post(
      `/api/abandoncart/removeDiscount`,
      abandonCart,
      config
    )

    dispatch({
      type: DISCOUNT_CODE_REMOVE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: DISCOUNT_CODE_REMOVE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const saveShippingMethodToStorage = (data) => async (dispatch) => {
  const abandonCart = {
    id: cookies.get('_cid'),
    paymentMethod: data,
  }
  try {
    dispatch({
      type: USER_CART_PAYMENT_REQUEST,
    })
    let config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const { data } = await axios.post(
      `/api/abandoncart/addPaymentMethod`,
      abandonCart,
      config
    )

    dispatch({
      type: USER_CART_PAYMENT_SUCCESS,
      payload: data,
    })
    cookies.set('_cid', data._id, {
      path: '/',
      maxAge: 34550000,
    })
  } catch (error) {
    dispatch({
      type: USER_CART_PAYMENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const fireFBPixel = (cartTotal) => (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState()
  const emailHash =
    userInfo && userInfo.email
      ? crypto
          .createHash('sha256')
          .update(userInfo.email.toLowerCase())
          .digest('hex')
      : null
  const phoneHash =
    userInfo && userInfo.phone
      ? crypto.createHash('sha256').update(userInfo.phone).digest('hex')
      : null
  if (userInfo && userInfo.email) {
    var data = {
      data: [
        {
          event_name: 'InitiateCheckout',
          event_time: Math.round(+new Date() / 1000),
          action_source: 'email',
          user_data: {
            em: [emailHash],
            ph: [phoneHash],
          },
          custom_data: {
            currency: 'INR',
            value: cartTotal,
          },
        },
      ],
    }
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    axios
      .post(process.env.FACEBOOK_CAPI, JSON.stringify(data), config)
      .then(function (response) {})
      .catch(function (error) {})
  }
}
export const saveDiscountPrice = (price) => (dispatch) => {
  dispatch({
    type: CART_SAVE_DISCOUNT,
    payload: price,
  })
  localStorage.setItem('discountPrice', price)
}

export const setEmailPopUpShown = (value) => async (dispatch) => {
  dispatch({
    type: EMAIL_POP_UP_SUCCESS,
    payload: value,
  })
  localStorage.setItem('emailPopUpShown', value)
}
export const listAbandonCart =
  (pagenumber, keyword) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ABANDON_CART_LIST_REQUEST,
      })
      const {
        userLogin: { userInfo },
      } = getState()
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const { data } = await axios.get(
        `/api/abandoncart/?pageNumber=${pagenumber}&keyword=${keyword}`,
        config
      )

      dispatch({
        type: ABANDON_CART_LIST_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: ABANDON_CART_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getCartDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ABANDON_CART_DETAILS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()
    let config = {}
    if (userInfo) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
    }
    const { data } = await axios.get(`/api/abandoncart/${id}`, config)

    dispatch({
      type: ABANDON_CART_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ABANDON_CART_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const submitEmail = (email) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EMAIL_SUBMIT_REQUEST,
    })
    cookies.set('_cfx_email', email, {
      path: '/',
      maxAge: 34550000,
    })
    let submitedEmail = {
      email: email,
    }
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const { data } = await axios.post(`/api/email`, submitedEmail, config)
    dispatch({
      type: EMAIL_SUBMIT_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: EMAIL_SUBMIT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const subscriberEmailList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: EMAIL_SUBMIT_LIST_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/email`, config)
    dispatch({
      type: EMAIL_SUBMIT_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: EMAIL_SUBMIT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const listcarts = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CART_LIST_REQUEST,
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/abandoncart/carts`, config)

    dispatch({
      type: CART_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CART_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const saveEmailToCart = (email) => async (dispatch) => {
  const abandonCart = {
    id: cookies.get('_cid'),
    email: email,
  }
  cookies.set('_cfx_phone', email, {
    path: '/',
    maxAge: 34550000,
  })
  try {
    dispatch({
      type: USER_CART_EMAIL_SAVE_REQUEST,
    })
    let config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const { data } = await axios.post(
      `/api/abandoncart/email`,
      abandonCart,
      config
    )

    dispatch({
      type: USER_CART_EMAIL_SAVE_SUCCESS,
      payload: data,
    })
    cookies.set('_cfx_phone_pop', true, {
      path: '/',
      maxAge: 34550000,
    })
  } catch (error) {
    dispatch({
      type: USER_CART_EMAIL_SAVE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const saveZipToCart =
  (phone, postalCode, city, state) => async (dispatch) => {
    const abandonCart = {
      id: cookies.get('_cid'),
      phone: phone,
      postalCode: postalCode,
      city: city,
      state: state,
    }
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const { data } = await axios.post(
        `/api/abandoncart/pincode`,
        abandonCart,
        config
      )
    } catch (error) {}
  }
export const editOrderadmin = (order) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_CART_EDIT_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.post(
      `/api/orders/${order.order}/edit`,
      order,
      config
    )
    dispatch({
      type: USER_CART_EDIT_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_CART_EDIT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
