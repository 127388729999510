import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

import {
  Button,
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  ListGroupItem,
  Form,
  FormGroup,
  Modal,
  Container,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import {
  getOrderDetails,
  deliverOrder,
  cancelOrder,
  confirmOrder,
  createOrderComment,
} from '../actions/orderActions'
import {
  createShipment,
  assignAwb,
  getShipmentDetails,
} from '../actions/shipmentActions'
import {
  ORDER_COMMENT_RESET,
  ORDER_DELEVERED_RESET,
} from '../constants/orderConstants'

const OrderScreen = ({ match, history }) => {
  const orderId = match.params.id
  const razorId = match.params.razid

  const dispatch = useDispatch()

  const orderDetails = useSelector((state) => state.orderDetails)
  const { order, loading, error, success } = orderDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const orderDeliver = useSelector((state) => state.orderDeliver)
  const {
    loading: loadingDeliver,
    error: errorDeliver,
    success: successDeliver,
  } = orderDeliver

  const orderPayStatus = useSelector((state) => state.orderPayStatus)
  const { loading: orderPayStatusLoading } = orderPayStatus

  const orderCancelRes = useSelector((state) => state.orderCancel)
  const { success: orderCancelSuccess } = orderCancelRes

  const orderConfirmRes = useSelector((state) => state.orderConfirm)
  const { success: orderConfirmSuccess } = orderConfirmRes

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const action = queryParams.get('action')

  const cancelOrderHandler = (e) => {
    dispatch(cancelOrder(order))
  }
  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    }
    if (action == 'confirm') {
      dispatch(confirmOrder({ _id: orderId }))
    } else if (action == 'cancel') {
      dispatch(cancelOrder({ _id: orderId }))
    } else if (
      (!order ||
        order._id !== orderId ||
        successDeliver ||
        orderPayStatusLoading) &&
      !loading
    ) {
      dispatch(getOrderDetails(orderId, 0))
      if (successDeliver) {
        dispatch({ type: ORDER_DELEVERED_RESET })
      }
    }
    if (orderCancelSuccess) {
      const path = window.location.pathname
      window.location.assign(path)
    }
    if (orderConfirmSuccess) {
      const path = window.location.pathname
      window.location.assign(path)
    }
  }, [
    dispatch,
    order,
    history,
    orderId,
    successDeliver,
    orderPayStatusLoading,
    loading,
    orderCancelSuccess,
    orderConfirmSuccess,
  ])

  return (
    <Container>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message>{error}</Message>
      ) : (
        <Container>
          <Row className='print-test'>
            <Col md={8}>
              <ListGroup variant='flush'>
                <ListGroup.Item>
                  <p>
                    <strong>{order.orderName}</strong>
                    <br />
                    <div className='topic'>
                      {order.isCancel ? 'CANCELLED' : order.status}
                      <br />
                      {order.isFufilled && (
                        <a href={`${order.trackingLink}`} target='_blank'>
                          Track your order
                        </a>
                      )}
                    </div>
                  </p>
                  {/* <p><strong>Email:</strong><a href={`mailto:${order.user.email}`}>{order.user.email}</a></p> */}
                  <p className='text-transform'>
                    {' '}
                    <strong>Name: </strong>{' '}
                    {order.user ? order.user.name : order.shippingAddress.name}
                    <br />
                    <strong>Email: </strong>{' '}
                    {order.user
                      ? order.user.email
                      : order.shippingAddress.email}
                    <br />
                    <strong>Address: </strong>
                    {order.shippingAddress.address},
                    {order.shippingAddress.address2},<br />
                    <strong>City: </strong> {order.shippingAddress.city}
                    <br />
                    <strong>State: </strong> {order.shippingAddress.state}
                    <br />
                    <strong>Zip Code: </strong>{' '}
                    {order.shippingAddress.postalCode}
                    <br />
                    <strong>Phone: </strong> {order.shippingAddress.phone}
                  </p>
                </ListGroup.Item>

                <ListGroup.Item>
                  <h5>Order Items</h5>
                  {order.orderItems.length == 0 ? (
                    <Message>Order is Empty</Message>
                  ) : (
                    <ListGroup variant='flush'>
                      {order.orderItems.map((item, index) => (
                        <ListGroup.Item key={index}>
                          <Row>
                            <Col md={2} xs={2}>
                              <Image
                                src={item.image}
                                alt={item.name}
                                fluid
                                rounded
                                className='cartImage'
                              />
                            </Col>
                            <Col m={5}>
                              <Link to={`/book/${item.isbn}/${item.name}`}>
                                {item.name}{' '}
                                {item.variantName &&
                                  '(' + item.variantName + ')'}
                              </Link>
                              <br />
                              {item.sellerName && (
                                <div>Seller: {item.sellerName}</div>
                              )}
                              {item.status && <div> Status: {item.status}</div>}
                              {item.tracking && (
                                <a href={item.tracking} target='_black'>
                                  Track Your Order
                                </a>
                              )}
                            </Col>
                            <Col md={2} xs={2}>
                              <span className='productPrice'>
                                &#8377;{item.salePrice}
                              </span>
                              <br />
                              <strike>&#8377;{item.price}</strike>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  )}
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={4}>
              <Card>
                <ListGroup variant='flash'>
                  <ListGroupItem>
                    <h5> Order Summary</h5>
                  </ListGroupItem>
                  <ListGroupItem>
                    <Row>
                      <Col>Items</Col>
                      <Col>&#8377;{order.itemPrice}</Col>
                    </Row>
                  </ListGroupItem>
                  <ListGroupItem>
                    <Row>
                      <Col>Shipping</Col>
                      <Col>&#8377;{order.shippingPrice}</Col>
                    </Row>
                  </ListGroupItem>
                  {order.codPrice > 0 && (
                    <ListGroupItem>
                      <Row>
                        <Col>COD Charges</Col>
                        <Col>&#8377;{order.codPrice}</Col>
                      </Row>
                    </ListGroupItem>
                  )}

                  {order.taxPrice > 0 && (
                    <ListGroupItem>
                      <Row>
                        <Col>Tax</Col>
                        <Col>&#8377;{order.taxPrice}</Col>
                      </Row>
                    </ListGroupItem>
                  )}

                  {order.discountPrice > 0 && (
                    <ListGroupItem>
                      <Row>
                        <Col>Discount</Col>
                        <Col>-&#8377;{order.discountPrice}</Col>
                      </Row>
                    </ListGroupItem>
                  )}

                  <ListGroupItem>
                    <Row>
                      <Col>Total</Col>
                      <Col>&#8377;{order.totalPrice}</Col>
                    </Row>
                  </ListGroupItem>
                  <ListGroup.Item>
                    <h5>Payment Method</h5>
                    <p>
                      <strong>Method: </strong>
                      {order.codPrice > 0 ? 'Cash On Delivery' : 'Paid'}
                    </p>
                    {/* {!order.isFufilled && !order.isCancel && !order.isPaid && (
                      <Button
                        style={{ marginTop: '5px' }}
                        onClick={cancelOrderHandler}
                      >
                        Cancel Order
                      </Button>
                    )} */}
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </Container>
  )
}

export default OrderScreen
