import axios from 'axios'
import {
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_CONFIRM_SUCCESS,
  ORDER_CONFIRM_FAIL,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_PAY_REQUEST,
  ORDER_PAY_SUCCESS,
  ORDER_PAY_FAIL,
  MY_ORDERS_DETAILS_REQUEST,
  MY_ORDERS_DETAILS_SUCCESS,
  MY_ORDERS_DETAILS_FAIL,
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_FAIL,
  ORDER_DELEVERED_REQUEST,
  ORDER_DELEVERED_SUCCESS,
  ORDER_DELEVERED_FAIL,
  ORDER_PAYMENT_CREATE_REQUEST,
  ORDER_PAYMENT_CREATE_SUCCESS,
  ORDER_PAYMENT_CREATE_FAIL,
  ORDER_CANCEL_REQUEST,
  ORDER_CANCEL_SUCCESS,
  ORDER_CANCEL_FAIL,
  ORDER_CONFIRM_REQUEST,
  ORDER_COMMENT_SUCCESS,
  ORDER_COMMENT_FAIL,
  ORDER_COMMENT_REQUEST,
  ORDER_ADMIN_SUCCESS,
  ORDER_ADMIN_FAIL,
  ORDER_ADMIN_REQUEST,
  ORDER_CURRATE_REQUEST,
  ORDER_CURRATE_SUCCESS,
  ORDER_CURRATE_FAIL,
  SELLER_ORDER_LIST_REQUEST,
  SELLER_ORDER_LIST_SUCCESS,
  SELLER_ORDER_LIST_FAIL,
  ORDER_NLIST_REQUEST,
  ORDER_NLIST_SUCCESS,
  ORDER_NLIST_FAIL,
  ORDER_OTP_REQUEST,
  ORDER_OTP_SUCCESS,
  ORDER_OTP_RESET,
  ORDER_OTP_FAIL,
  ORDER_TRACK_REQUEST,
  ORDER_TRACK_SUCCESS,
  ORDER_TRACK_FAIL,
} from '../constants/orderConstants'

export const createOrderAB = (order) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()
    let config = {}
    if (userInfo) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const { data } = await axios.post(`/api/orders/admin`, order, config)

      dispatch({
        type: ORDER_CREATE_SUCCESS,
        payload: data,
      })
    }

    // const abandonCart = {
    //   id: localStorage.getItem('cartId'),
    //   isConverted: true,
    //   shippingMethod: JSON.parse('Standard'),
    //   paymentMethod: JSON.parse(localStorage.getItem('paymentMethod')),
    //   shippingAddress: JSON.parse(localStorage.getItem('shippingAddress')),
    //   cartItems: JSON.parse(localStorage.getItem('cartItems')),
    // }
    // try {
    //   let config = {
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //   }
    //   const { data } = await axios.post(`/api/abandoncart`, abandonCart, config)
    // } catch (error) {}
  } catch (error) {
    dispatch({
      type: ORDER_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createOrder = (order) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()
    let config = {}
    if (userInfo) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const { data } = await axios.post(`/api/orders`, order, config)

      dispatch({
        type: ORDER_CREATE_SUCCESS,
        payload: data,
      })
    }

    // const abandonCart = {
    //   id: localStorage.getItem('cartId'),
    //   isConverted: true,
    //   shippingMethod: JSON.parse('Standard'),
    //   paymentMethod: JSON.parse(localStorage.getItem('paymentMethod')),
    //   shippingAddress: JSON.parse(localStorage.getItem('shippingAddress')),
    //   cartItems: JSON.parse(localStorage.getItem('cartItems')),
    // }
    // try {
    //   let config = {
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //   }
    //   const { data } = await axios.post(`/api/abandoncart`, abandonCart, config)
    // } catch (error) {}
  } catch (error) {
    dispatch({
      type: ORDER_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getOrderDetails =
  (id, type = 0) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ORDER_DETAILS_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()
      let config = {}
      if (userInfo) {
        config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
        const { data } = await axios.get(`/api/orders/${id}/${type}`, config)

        dispatch({
          type: ORDER_DETAILS_SUCCESS,
          payload: data,
        })
      }
    } catch (error) {
      dispatch({
        type: ORDER_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const payOrder = (orderId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_PAY_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()
    let config = {}
    if (userInfo) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const { data } = await axios.get(`/api/orders/${orderId}/pay`, config)

      dispatch({
        type: ORDER_PAY_SUCCESS,
        payload: data,
      })
    }
  } catch (error) {
    dispatch({
      type: ORDER_PAY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deliverOrder = (order, Link) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DELEVERED_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const link = {
      link: Link,
    }
    const { data } = await axios.put(
      `/api/orders/${order._id}/deliver`,
      link,
      config
    )

    dispatch({
      type: ORDER_DELEVERED_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ORDER_DELEVERED_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const cancelOrder = (order) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_CANCEL_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.put(
      `/api/orders/${order._id}/cancel`,
      {},
      config
    )

    dispatch({
      type: ORDER_CANCEL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ORDER_CANCEL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const confirmOrder = (order) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_CONFIRM_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.put(
      `/api/orders/${order._id}/confirm`,
      {},
      config
    )

    dispatch({
      type: ORDER_CONFIRM_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ORDER_CONFIRM_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const listMyOrders =
  (pageNumber = 1) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: MY_ORDERS_DETAILS_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const { data } = await axios.get(
        `/api/orders/myorders?pageNumber=${pageNumber}`,
        config
      )

      dispatch({
        type: MY_ORDERS_DETAILS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: MY_ORDERS_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const listOrders =
  (pageNumber, cancelOrders, keyword, date, filter) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ORDER_LIST_REQUEST,
      })
      const {
        userLogin: { userInfo },
      } = getState()
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const { data } = await axios.get(
        `/api/orders?pageNumber=${pageNumber}&cancelOrders=${cancelOrders}&keyword=${keyword}&date=${date}&sfilter=${filter}`,
        config
      )

      dispatch({
        type: ORDER_LIST_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: ORDER_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
export const listSellerOrders =
  (pageNumber, cancelOrders, keyword, date) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SELLER_ORDER_LIST_REQUEST,
      })
      const {
        userLogin: { userInfo },
      } = getState()
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const { data } = await axios.get(
        `/api/orders/seller?pageNumber=${pageNumber}&cancelOrders=${cancelOrders}&keyword=${keyword}&date=${date}`,
        config
      )

      dispatch({
        type: SELLER_ORDER_LIST_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: SELLER_ORDER_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
export const postOrderPayment = (order) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_PAYMENT_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()
    let config = {}
    if (userInfo) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const { data } = await axios.post(`/api/orders/payorder`, order, config)

      dispatch({
        type: ORDER_PAYMENT_CREATE_SUCCESS,
        payload: data,
      })
    }
  } catch (error) {
    dispatch({
      type: ORDER_PAYMENT_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const createOrderComment =
  (orderId, comment) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ORDER_COMMENT_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      await axios.post(`/api/orders/${orderId}/comment`, comment, config)

      dispatch({
        type: ORDER_COMMENT_SUCCESS,
      })
    } catch (error) {
      dispatch({
        type: ORDER_COMMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
export const addTagtoOrder = (tags, orderId) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    await axios.post(
      `/api/orders/${orderId}/update/tags`,
      { tags: tags },
      config
    )
  } catch (error) {}
}
export const adminOrderList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_ADMIN_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/orders/admin`, config)

    dispatch({
      type: ORDER_ADMIN_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ORDER_ADMIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const currateBox = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_CURRATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/orders/${id}/currateBox`, config)

    dispatch({
      type: ORDER_CURRATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ORDER_CURRATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const getNotifications = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_NLIST_REQUEST,
    })
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const { data } = await axios.get(`/api/orders/notifications`, config)

    dispatch({
      type: ORDER_NLIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ORDER_NLIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const sendOrderOTP = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_OTP_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.get(`/api/orders/${id}/sendorderotp`, config)

    dispatch({
      type: ORDER_OTP_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ORDER_OTP_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const trackOrder = (orderNumber) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_TRACK_REQUEST,
    })

    const { data } = await axios.post(`/api/orders/trackorder`, {
      orderNumber: orderNumber,
    })

    dispatch({
      type: ORDER_TRACK_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ORDER_TRACK_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const sendTrackingDetails = (id) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    await axios.get(`/api/orders/${id}/sendtracking`, config)
  } catch (error) {}
}
