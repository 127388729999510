import { Route, BrowserRouter } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import HomeScreen from './screens/HomeScreen'
import ProductScreen from './screens/ProductScreen'
import CartScreen from './screens/CartScreen'
import LoginScreen from './screens/LoginScreen'
import RegisterScreen from './screens/RegisterScreen'
import ProfileScreen from './screens/ProfileScreen'
import ShippingScreen from './screens/ShippingScreen'
import PaymentScreen from './screens/PaymentScreen'
import PlaceOrderScreen from './screens/PlaceOrderScreen'
import OrderScreen from './screens/OrderScreen'
import UserListScreen from './screens/UserListScreen'
import UserEditScreen from './screens/UserEditScreen'
import ProductListScreen from './screens/ProductListScreen'
import ProductEditScreen from './screens/ProductEditScreen'
import OrderListScreen from './screens/OrderListScreen'
import TermsConditionScreen from './screens/static/TermsConditionScreen'
import PrivacyPolicyScreen from './screens/static/PrivacyPolicyScreen'
import ReturnRefundScreen from './screens/static/ReturnRefundScreen'
import AboutUsScreen from './screens/static/AboutUsScreen'
import CategoryProductScreen from './screens/CategoryProductScreen'
import OrderConfirmScreen from './screens/OrderConfirmScreen'
import BasicInformationScreen from './screens/BasicInformationScreen'
import AbandonCartListScreen from './screens/AbandonCartListScreen'
import { AdminDashboardScreen } from './screens/AdminDashboardScreen'
import DiscountCodeScreen from './screens/DiscountCodeScreen'
import SubscriberListScreen from './screens/SubscriberListScreen'
import AuthorProductScreen from './screens/AuthorProductScreen'
import AbandonCart from './screens/AbandonCartScreen'
import FlowListScreen from './screens/FlowListScreen'
import FlowDetailScreen from './screens/FlowDetailScreen'
import ProductScanScreen from './screens/scan/ProductScanScreen'
import UserOrderListScreen from './screens/UserOrderListScreen'
import UserAddressListScreen from './screens/UserAddressListScreen'
import UserSettingScreen from './screens/UserSettingScreen'
import UserCoinListScreen from './screens/UserCoinListScreen'
import LanguageProductScreen from './screens/LanguageProductScreen'
import UserWishListScreen from './screens/UserWishListScreen'
import UserEbookScreen from './screens/UserEbookScreen'
import UserSubscriptionScreen from './screens/UserSubscriptionScreen'
import EbooksScreen from './screens/EbooksScreen'
import ProductNewScreen from './screens/ProductNewScreen'
import ContactFormScreen from './screens/ContactFormScreen'
import SubmissionScreen from './screens/SubmissionScreen'
import ContactFromSubmissionScreen from './screens/ContactFromSubmissionScreen'
import EbookSubmissionsScreen from './screens/EbookSubmissionsScreen'
import SearchScreen from './screens/SearchScreen'
import RedirectProductScreen from './screens/RedirectProductScreen'
import RedirectCategoryProductScreen from './screens/RedirectCategoryProductScreen'
import BookFairNewScreen from './screens/BookFairNewScreen'
import BookFairListScreen from './screens/BookFairListScreen'
import BookFairScreen from './screens/BookFairScreen'
import BookFairEntryNewScreen from './screens/BookFairEntryNewScreen'
import BookFairEntryStatsScreen from './screens/BookFairEntryStatsScreen'
import GenreScreen from './screens/static/GenreScreen'
import ReviewScreen from './screens/ReviewScreen'
import AddressSelectionScreen from './screens/AddressSelectionScreen'
import CartListScreen from './screens/CartListScreen'
import BoxScreen from './screens/BoxScreen'
import IntrestListScreen from './screens/IntrestListScreen'
import ExpressCheckout from './screens/ExpressCheckout'
import BookFairPScreen from './screens/BookFairPScreen'
import BookFairListPScreen from './screens/BookFairListPScreen'
import BookFairIntrestScreen from './screens/BookFairIntrestScreen'
import CretaeEpisodeScreen from './screens/stories/CretaeEpisodeScreen'
import CretaeStoryScreen from './screens/stories/CretaeStoryScreen'
import StoryListScreen from './screens/stories/StoryListScreen'
import WriteEpisodeScreen from './screens/stories/WriteEpisodeScreen'
import StoryViewScreen from './screens/ideas/StoryViewScreen'
import MyStoryListScreen from './screens/stories/MyStoryListScreen'
import ReadEpisodeScreen from './screens/app/AppReadEpisodeScreen'
import FollowerScreen from './screens/stories/FollowerScreen'
import FollowingScreen from './screens/stories/FollowingScreen'
import LoginOTPScreen from './screens/LoginOTPScreen'
import PasswordResetScreen from './screens/PasswordResetScreen'
import UserScreen from './screens/UserScreen'
import StoriesScreen from './screens/ideas/StoriesScreen'
import AllStoryListScreen from './screens/stories/AllStoryListScreen'
import SummariesScreen from './screens/summaries/SummariesScreen'
import SubscribeScreen from './screens/summaries/SubscribeScreen'

import CreateBlogScreen from './screens/blog/CreateBlogScreen'
import ListBlogScreen from './screens/blog/ListBlogScreen'
import ViewBlogScreen from './screens/blog/ViewBlogScreen'
import AdminOrderScreen from './screens/admin/AdminOrderScreen'
import SuprieseBoxScreen from './screens/landingPages/SuprieseBoxScreen'
import AllLinksScreen from './screens/all/AllLinksScreen'

import StoriesListScreen from './screens/ideas/StoriesListScreen'

import AppStoriesListScreen from './screens/app/AppStoriesListScreen'
import AppStoryViewScreen from './screens/app/AppStoryViewScreen'
import AppReadEpisodeScreen from './screens/app/AppReadEpisodeScreen'
import UserSubscriptionList from './screens/app/UserSubscriptionList'

import ReadingsScreen from './screens/app/ReadingsScreen'
import StoriesScreenUS from './screens/ideas/StoriesScreenUS'
import BillingScreenUS from './screens/BillingScreenUS'
import AdminMenuScreen from './screens/admin/AdminMenuScreen'
import ThemeScreen from './screens/ai/ThemeScreen'
import OnlineBookFairScreen from './screens/bookfair/OnlineBookFairScreen'
import BarcodeListScreen from './screens/scan/BarcodeListScreen'
import ProductScanScreenNew from './screens/scan/ProductScanScreenNew'
import OrderPrintScreen from './screens/admin/OrderPrintScreen'
import PlotsHomeScreen from './screens/plots/public/PlotsHomeScreen'
import PlotListScreen from './screens/plots/public/PlotListScreen'
import PlotViewScreen from './screens/plots/public/PlotViewScreen'
import ReadFreePlotsScreen from './screens/plots/public/ReadFreePlotsScreen'
import AppHomeScreen from './screens/plots/app/AppHomeScreen'
import AppPlotListScreen from './screens/plots/app/AppPlotListScreen'
import AppPlotViewScreen from './screens/plots/app/AppPlotViewScreen'
import AppReadPlotsScreen from './screens/plots/app/AppReadPlotsScreen'
import AppPlotSearchScreen from './screens/plots/app/AppPlotSearchScreen'
import AppReadPlotListScreen from './screens/plots/app/AppReadPlotListScreen'
import SubscriptionList from './screens/plots/app/SubscriptionList'
import BillingScreen from './screens/plots/app/BillingScreen'
import SubscriptionSuccess from './screens/plots/app/SubscriptionSuccess'
import SellerHomeScreen from './screens/seller/public/SellerHomeScreen'
import AddNewBookScreen from './screens/seller/app/AddNewBookScreen'
import CreateStoreScreen from './screens/seller/app/CreateStoreScreen'
import SellerAppHomeScreen from './screens/seller/app/SellerAppHomeScreen'
import SellerBooksScreen from './screens/seller/admin/SellerBooksScreen'
import SellerOrderListScreen from './screens/seller/app/SellerOrderListScreen'
import EditBookScreen from './screens/seller/app/EditBookScreen'
import SellerStoreScreen from './screens/seller/app/SellerStoreScreen'
import SellerPaymentScreen from './screens/seller/app/SellerPaymentScreen'
import SellerOrderScreen from './screens/seller/app/SellerOrderScreen'
import SellerBooksListScreen from './screens/seller/app/SellerBooksListScreen'
import StoreHomeScreen from './screens/seller/public/StoreHomeScreen'
import FictionBookScreen from './screens/genres/FictionBookScreen'
import NonFictionBookScreen from './screens/genres/NonFictionBookScreen'
import GenreBookScreen from './screens/genres/GenreBookScreen'
import StoreListScreen from './screens/seller/admin/StoreListScreen'
import EditBlogScreen from './screens/blog/EditBlogScreen'
import PlotAuthorScreen from './screens/plots/public/PlotAuthorScreen'
import ViewStoryScreen from './stories/ViewStoryScreen'
import ListStoriesScreen from './stories/ListStoriesScreen'
import ShayariScreen from './screens/blog/shayari/ShayariScreen'
import ShayariTypeScreen from './screens/blog/shayari/ShayariTypeScreen'
import ProductScanScreenBox from './screens/scan/ProductScanScreenBox'
import SellerRegisterScreen from './screens/seller/public/SellerRegisterScreen'
import UnsubscribeScreen from './screens/UnsubscribeScreen'
import TrackOrderScreen from './screens/TrackOrderScreen'
import AdminSellerHomeScreen from './screens/seller/admin/AdminSellerHomeScreen'
import ShipmentListScreen from './screens/shipments/ShipmentListScreen'
import CheckoutScreen from './screens/CheckoutScreen'
import { AdminStatsDashboardScreen } from './screens/AdminStatsDashboardScreen'
const App = () => {
  return (
    // eslint-disable-next-line
    <BrowserRouter>
      <Header />
      <>
        <main>
          <Route
            path='/fiction-books/:genre/page/:pageNumber'
            component={GenreBookScreen}
            exact
          />
          {/* <Route
            path='/fiction-books/:genre/page/:pageNumber'
            component={GenreBookScreen}
            exact
          /> */}
          <Route
            path='/fiction-books/:genre'
            component={GenreBookScreen}
            exact
          />
          <Route
            path='/fiction-books/page/:pageNumber'
            component={FictionBookScreen}
            exact
          />
          <Route path='/fiction-books' component={FictionBookScreen} exact />
          <Route
            path='/non-fiction-books/:genre/page/:pageNumber'
            component={GenreBookScreen}
            exact
          />
          <Route
            path='/non-fiction-books/:genre'
            component={GenreBookScreen}
            exact
          />
          <Route
            path='/non-fiction-books/page/:pageNumber'
            component={NonFictionBookScreen}
            exact
          />
          <Route
            path='/non-fiction-books'
            component={NonFictionBookScreen}
            exact
          />
          <Route
            path='/admin/stores/page/:pageNumber'
            component={StoreListScreen}
            exact
          />
          <Route path='/admin/stores' component={StoreListScreen} exact />
          <Route
            path='/admin/seller-books/page/:pageNumber'
            component={SellerBooksScreen}
            exact
          />
          <Route
            path='/admin/seller-books'
            component={SellerBooksScreen}
            exact
          />
          <Route
            path='/store/:name/:id/page/:pageNumber'
            component={StoreHomeScreen}
            exact
          />
          <Route
            path='/seller/register'
            component={SellerRegisterScreen}
            exact
          />
          <Route path='/store/:name/:id' component={StoreHomeScreen} exact />
          <Route
            path='/seller/books/page/:pageNumber'
            component={SellerBooksListScreen}
            exact
          />
          <Route path='/seller/books' component={SellerBooksListScreen} exact />
          <Route path='/seller/store' component={SellerStoreScreen} exact />
          <Route path='/seller/payment' component={SellerPaymentScreen} exact />
          <Route path='/seller/order/:id' component={SellerOrderScreen} exact />
          <Route
            path='/seller/orders/page/:pageNumber'
            component={SellerOrderListScreen}
            exact
          />
          <Route
            path='/seller/orders'
            component={SellerOrderListScreen}
            exact
          />
          <Route path='/sell-books' component={SellerHomeScreen} exact />
          <Route path='/sell/new/book' component={AddNewBookScreen} exact />
          <Route
            path='/seller/edit/book/:id'
            component={EditBookScreen}
            exact
          />

          <Route
            path='/seller/create/new/store'
            component={CreateStoreScreen}
            exact
          />
          <Route
            path='/seller/dashboard'
            component={SellerAppHomeScreen}
            exact
          />
          <Route path='/ai-curated-box' component={ThemeScreen} exact />
          <Route
            path='/online-book-fair'
            component={OnlineBookFairScreen}
            exact
          />
          {/* landing-page */}
          <Route
            path='/99bookscart-surprise-box'
            component={SuprieseBoxScreen}
            exact
          />
          {/* landing-page */}
          {/* sitemap*/}
          <Route path='/all-books' component={AllLinksScreen} exact />
          {/* sitemap */}
          {/* Stories */}
          <Route path='/web-stories' component={ListStoriesScreen} exact />

          <Route
            path='/web-stories/:id/:title'
            component={ViewStoryScreen}
            exact
          />
          {/* blog */}

          <Route path='/blog/:title' component={ViewBlogScreen} exact />
          <Route
            path='/blog/view/:id/:title'
            component={ViewBlogScreen}
            exact
          />
          <Route path='/blog/edit/:id' component={EditBlogScreen} exact />
          <Route path='/blog/create/new' component={CreateBlogScreen} exact />
          <Route
            path='/blog/page/:pageNumber'
            component={ListBlogScreen}
            exact
          />
          <Route path='/blog' component={ListBlogScreen} exact />
          <Route path='/shayari' component={ShayariScreen} exact />
          <Route path='/shayari/:title' component={ShayariTypeScreen} exact />
          {/* end blog */}

          {/* Stories */}
          <Route
            path='/plots/en/author/:author/:id'
            component={PlotAuthorScreen}
            exact
          />
          <Route
            path='/plots/en/read/:id/:sid'
            component={ReadFreePlotsScreen}
            exact
          />
          <Route path='/plots' component={PlotsHomeScreen} exact />
          <Route
            path='/plots/en/page/:pageNumber'
            component={PlotsHomeScreen}
            exact
          />
          <Route
            path='/plots/en/category/:category'
            component={PlotListScreen}
            exact
          />
          <Route
            path='/plots/en/category/:category/page/:pageNumber'
            component={PlotListScreen}
            exact
          />
          <Route
            path='/plots/en/book/:title/:id'
            component={PlotViewScreen}
            exact
          />
          <Route
            path='/app/en/search/:keyword'
            component={AppPlotSearchScreen}
            exact
          />
          <Route
            path='/app/en/search/:keyword/page/:pageNumber'
            component={AppPlotSearchScreen}
            exact
          />
          <Route path='/app/home' component={AppHomeScreen} exact />
          <Route
            path='/app/en/category/:category'
            component={AppPlotListScreen}
            exact
          />
          <Route
            path='/app/en/category/:category/page/:pageNumber'
            component={AppPlotListScreen}
            exact
          />
          <Route
            path='/app/en/book/:title/:id'
            component={AppPlotViewScreen}
            exact
          />

          <Route
            path='/app/en/plot/:id/'
            component={AppReadPlotsScreen}
            exact
          />
          <Route
            path='/app/profile/readings'
            component={AppReadPlotListScreen}
            exact
          />
          <Route
            path='/app/profile/subscriptions'
            component={SubscriptionList}
          />
          <Route
            path='/app/subscribed/:id/:id2/:id3'
            component={SubscriptionSuccess}
            exact
          />
          <Route path='/app/profile/billing' component={BillingScreen} />
          <Route
            path='/write/story/:id/:sid'
            component={CretaeStoryScreen}
            exact
          />
          <Route path='/create/episode' component={CretaeEpisodeScreen} exact />
          <Route
            path='/create/episode/:sid'
            component={CretaeEpisodeScreen}
            exact
          />
          <Route
            path='/write/episode/:id/:sid'
            component={WriteEpisodeScreen}
            exact
          />
          <Route
            path='/profile/mystories'
            component={MyStoryListScreen}
            exact
          />

          <Route
            path='/profile/subscriptions'
            component={UserSubscriptionList}
          />

          <Route path='/profile/followers' component={FollowerScreen} exact />
          <Route path='/profile/following' component={FollowingScreen} exact />
          <Route
            path='/app/admin/story/all'
            component={AllStoryListScreen}
            exact
          />
          <Route
            path='/app/admin/story/all/page/:pageNumber'
            component={AllStoryListScreen}
            exact
          />
          <Route path='/story/list' component={StoryListScreen} exact />
          <Route
            path='/story/list/page/:pageNumber'
            component={StoryListScreen}
            exact
          />

          {/* End of Stories */}

          <Route
            path='/passwordreset/:id/:reset'
            component={PasswordResetScreen}
            exact
          />
          <Route path='/admin/intrests' component={IntrestListScreen} exact />

          <Route path='/categories' component={GenreScreen} exact />
          <Route
            path='/admin/bookfair/:id/stats'
            component={BookFairEntryStatsScreen}
            exact
          />
          <Route
            path='/admin/bookfair/:id/new'
            component={BookFairEntryNewScreen}
          />
          <Route path='/bookfairs' component={BookFairListPScreen} exact />
          <Route
            path='/admin/bookfair/intrest/:id'
            component={BookFairIntrestScreen}
            exact
          />
          <Route path='/bookfair/:id' component={BookFairPScreen} exact />
          <Route path='/admin/bookfair/:id' component={BookFairScreen} exact />
          <Route
            path='/admin/bookfair/:id/page/:pageNumber'
            component={BookFairScreen}
            exact
          />
          <Route path='/admin/bookfairList' component={BookFairListScreen} />
          <Route path='/admin/bookfairNew' component={BookFairNewScreen} />
          <Route path='/admin/order/:id' component={AdminOrderScreen} exact />
          <Route
            path='/admin/order/:id/print'
            component={OrderPrintScreen}
            exact
          />
          <Route path='/admin/menu' component={AdminMenuScreen} exact />
          <Route
            path='/admin/barcode-gen'
            component={BarcodeListScreen}
            exact
          />
          <Route
            path='/products/:slug'
            component={RedirectProductScreen}
            exact
          />
          <Route
            path='/collections/:slug'
            component={RedirectCategoryProductScreen}
          />
          <Route path='/search' component={SearchScreen} exact />
          <Route path='/contact-us' component={ContactFormScreen} exact />
          <Route path='/submission' component={SubmissionScreen} exact />

          <Route path='/order/:id' component={OrderScreen} exact />
          <Route
            path='/order/:id/orderconfirm/:razid'
            component={OrderConfirmScreen}
            exact
          />
          <Route path='/ebooks' component={EbooksScreen} exact />
          <Route
            path='/category/:category'
            component={CategoryProductScreen}
            exact
          />
          <Route path='/author/:author' component={AuthorProductScreen} exact />
          <Route
            path='/language/:language'
            component={LanguageProductScreen}
            exact
          />
          <Route
            path='/language/:language/page/:pageNumber'
            component={LanguageProductScreen}
            exact
          />
          <Route
            path='/category/:category/page/:pageNumber'
            component={CategoryProductScreen}
            exact
          />
          <Route path='/checkout/placeorder' component={PlaceOrderScreen} />
          <Route path='/search/:keyword/:search' component={HomeScreen} exact />
          <Route path='/page/:pageNumber' component={HomeScreen} exact />
          <Route
            path='/search/:keyword/page/:pageNumber'
            component={HomeScreen}
            exact
          />
          <Route path='/' component={HomeScreen} exact />
          <Route path='/review/:id/:slug' component={ReviewScreen} exact />
          <Route path='/book/:id/:slug' component={ProductScreen} />
          <Route path='/landing/:id/:slug' component={BoxScreen} exact />
          {/* <Route path='/read/:id/:slug' component={ReadScreen} /> */}
          <Route path='/cart/:id?' component={CartScreen} />
          <Route path='/login2' component={LoginOTPScreen} />
          <Route path='/login' component={LoginScreen} />

          <Route path='/register' component={RegisterScreen} />
          <Route path='/profile' component={ProfileScreen} exact />
          <Route path='/user/:id' component={UserScreen} exact />
          <Route
            path='/profile/orders/page/:pageNumber'
            component={UserOrderListScreen}
            exact
          />
          <Route path='/profile/orders' component={UserOrderListScreen} exact />
          <Route
            path='/profile/addresses'
            component={UserAddressListScreen}
            exact
          />
          <Route path='/profile/setting' component={UserSettingScreen} exact />
          <Route path='/profile/coins' component={UserCoinListScreen} exact />
          <Route path='/profile/ebooks' component={UserEbookScreen} exact />
          <Route
            path='/profile/mylibrary'
            component={UserWishListScreen}
            exact
          />
          <Route
            path='/profile/subscription'
            component={UserSubscriptionScreen}
          />
          <Route path='/checkout' component={CheckoutScreen} exact />
          <Route path='/checkout/express' component={ExpressCheckout} />
          <Route path='/checkout/shipping' component={ShippingScreen} />
          <Route path='/checkout/payment' component={PaymentScreen} />
          <Route
            path='/checkout/basic-information'
            component={BasicInformationScreen}
          />
          <Route
            path='/checkout/select-address'
            component={AddressSelectionScreen}
          />
          <Route
            path='/admin/store/:name/:id'
            component={AdminSellerHomeScreen}
            exact
          />
          <Route path='/admin/dashboard' component={AdminDashboardScreen} />
          <Route path='/admin/dashboard2' component={AdminStatsDashboardScreen} />
          <Route path='/admin/userList' component={UserListScreen} exact />
          <Route
            path='/admin/userList/:pageNumber'
            component={UserListScreen}
            exact
          />
          <Route path='/admin/user/:id/edit' component={UserEditScreen} />
          <Route
            path='/admin/product/scan'
            component={ProductScanScreen}
            exact
          />
          <Route
            path='/admin/product/scanNew'
            component={ProductScanScreenNew}
            exact
          />
          <Route
            path='/admin/product/boxScan'
            component={ProductScanScreenBox}
            exact
          />
          <Route
            path='/admin/formsubmissions'
            component={ContactFromSubmissionScreen}
            exact
          />
          <Route path='/admin/shipments' component={ShipmentListScreen} exact />
          {/* <Route
            path='/admin/ebooksubmissions'
            component={EbookSubmissionsScreen}
            exact
          /> */}

          <Route
            path='/admin/productlist'
            component={ProductListScreen}
            exact
          />
          <Route path='/admin/product/new' component={ProductNewScreen} exact />
          <Route
            path='/admin/productlist/:pageNumber'
            component={ProductListScreen}
            exact
          />
          <Route path='/admin/product/:id/edit' component={ProductEditScreen} />
          <Route path='/admin/orderlist' component={OrderListScreen} exact />
          <Route
            path='/admin/orderlist/:pageNumber'
            component={OrderListScreen}
            exact
          />
          <Route
            path='/admin/orderlist/:pageNumber/:keyword'
            component={OrderListScreen}
            exact
          />
          <Route
            path='/admin/orderlist/:pageNumber/type/:cancel'
            component={OrderListScreen}
            exact
          />
          <Route
            path='/admin/abandoncartlist'
            component={AbandonCartListScreen}
            exact
          />
          <Route
            path='/admin/abandoncartlist/:pageNumber/:keyword'
            component={AbandonCartListScreen}
            exact
          />
          <Route path='/admin/abandoncartdetails/:id' component={AbandonCart} />
          <Route path='/admin/cartlist' component={CartListScreen} exact />

          <Route path='/admin/discountCode' component={DiscountCodeScreen} />
          <Route path='/admin/flowlist' component={FlowListScreen} exact />
          <Route path='/admin/flow/:id' component={FlowDetailScreen} exact />
          <Route
            path='/admin/subscriberList'
            component={SubscriberListScreen}
          />
          <Route
            path='/pages/terms-and-condition'
            component={TermsConditionScreen}
          />
          <Route path='/pages/privacy-policy' component={PrivacyPolicyScreen} />
          <Route path='/pages/return-refund' component={ReturnRefundScreen} />
          <Route path='/pages/about-us' component={AboutUsScreen} />
          <Route path='/unsubscribe' component={UnsubscribeScreen} />
          <Route path='/trackorder' component={TrackOrderScreen} />
        </main>
      </>
      <Footer />
    </BrowserRouter>
  )
}

export default App
