import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  ProgressBar,
  Form,
  Row,
  Container,
  Col,
  Modal,
} from 'react-bootstrap'
// import LoginForm from './LoginForm'
// import NewLogin from './NewLogin'
import { Link } from 'react-router-dom'
import Cookies from 'universal-cookie'
import {
  addToCart,
  saveEmailToCart,
  saveZipToCart,
} from '../actions/cartActions'
import Loader from './Loader'
import axios from 'axios'
import { TextField, Typography } from '@mui/material'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { FaCartPlus } from 'react-icons/fa'
import {
  userAddressList,
  deleteUserAddress,
  createGuestUserAddress,
} from '../actions/userActions'
import Razorpay from 'razorpay'
import { v4 as uuidv4 } from 'uuid'
import {
  createOrder,
  payOrder,
  postOrderPayment,
} from '../actions/orderActions'
import OrderSummary from './OrderSummary'
function timeout(delay) {
  return new Promise((res) => setTimeout(res, delay))
}
const StepFunnel = ({ history, product }) => {
  const cookies = new Cookies()
  const [currentStep, setCurrentStep] = useState(1)
  const [onboardArray, setOnboardArray] = useState([])
  const [vprice, setVprice] = useState(0)
  const [vId, SetVid] = useState(0)
  const [boxType, setBoxType] = useState('1')
  const [inventoryId, setInventoryId] = useState(null)
  const [vmessage, setVmessage] = useState('')
  const [cartClick, setCartClick] = React.useState(false)
  const [addToCartMessage, setAddToCartMessage] = useState(false)
  const [show, setShow] = useState(cookies.get('start_funnel') ? false : true)
  const [showCOD, setShowCOD] = useState(false)

  const [showLoader, setShowLoader] = useState(false)
  const [redirect, setRedirect] = useState(false)

  const [name, setName] = useState('')
  const [nameMessage, setNameMessage] = useState('')
  const [email, setEmail] = useState('')
  const [emailMessage, setEmailMessage] = useState('')
  const [address, setAddress] = useState('')
  const [address1, setAddress1] = useState('')
  const [addressMessage, setAddressMessage] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [payClick, setPayClick] = useState(false)
  const [pmMessage, setPmMessage] = useState('')
  const userCart = useSelector((state) => state.userCart)
  const { cart, loading: cartLoading, success: cartSuccess } = userCart
  const [phoneMessage, setPhoneMessage] = useState('')
  const [zipError, setZipError] = useState('')
  const orderCreate = useSelector((state) => state.orderCreate)
  const { order, success: orderSuccess, error } = orderCreate
  const guestUser = useSelector((state) => state.guestUser)
  const { user, success: guestSuccess, error: guestError } = guestUser
  const [phone, setPhone] = useState(false)
  const [posterMsgs, setPosterMsgs] = useState('Add to cart')
  const [bookmarkMsgs, setBookmarkMsgs] = useState('Add to cart Free')
  const [pincode, setPincode] = useState('')
  const [infoShow, setInfoShow] = useState(false)
  const [userInputs, setUserInputs] = useState({
    ageRange: '',
    gender: '',
    vprice: '',
    vId: '',
    interests: [],
  })
  var days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]
  var months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2)
  }
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }
  var date = new Date()
  var deliveryDate = new Date(date.setTime(date.getTime() + 7 * 86400000))
  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const x = {
    _id: 'asdasd',
    name: 'asdasd',
    price: 'asdasd',
  }
  useEffect(() => {
    loadScript('https://checkout.razorpay.com/v1/checkout.js')
    if (order && orderSuccess) {
      cookies.remove('_cid', {
        path: '/',
      })
      if (!order.isPaid) {
        history.push(`/order/${order._id}/orderconfirm/cod`)
      } else {
        history.push(
          `/order/${order._id}/orderconfirm/${order.paymentResult.rid}`
        )
      }
    }
    if (guestSuccess && payClick) {
      if (user.pmethod == 'online') {
        var options = {
          // key: 'rzp_test_Uc74kK1ulMDQoS',
          key: 'rzp_live_04AG18531Cjx9B',
          amount: cart.totalPrice,
          currency: 'INR',
          name: name,
          description: '99bookscart',
          order_id: user.presults.id,
          image:
            'https://cdn.shopify.com/s/files/1/0287/9612/5316/files/99bookscart_759b028b-6768-47b8-9d79-ee2cf0190e04_180x.png?v=1646232311',
          handler: function (response) {
            const paymentResult = {
              id: response.razorpay_payment_id,
              rid: response.razorpay_order_id,
              signature: response.razorpay_signature,
            }
            dispatch(
              createOrder({
                orderItems: cart.cartItems,
                shippingAddress: cart.shippingAddress,
                paymentMethod: 'online',
                shippingMethod: 'Standard',
                itemPrice: cart.cartPrice,
                shippingPrice: cart.shippingPrice,
                taxPrice: cart.taxPrice,
                totalPrice: cart.totalPrice,
                codPrice: 0,
                isPaid: response.razorpay_order_id ? true : false,
                paidAt: response.razorpay_order_id ? Date.now() : '',
                paymentResult: paymentResult,
                cartId: cart._id,
              })
            )
            timeout(1000)
          },
          prefill: {
            name: name,
            email: email,
            contact: phone,
          },
          theme: {
            color: '#4267b2',
          },
        }
        if (payClick) {
          const paymentObject = new window.Razorpay(options)
          paymentObject.open()
        }
      } else {
        if (!order) {
          const paymentResult = {
            id: uuidv4(),
            rid: null,
            signature: null,
          }
          dispatch(
            createOrder({
              paymentMethod: 'cod',
              shippingMethod: 'Standard',
              discount: {},
              paymentResult: paymentResult,
              cartId: cart._id,
            })
          )
        }
      }
    }
    if (cartSuccess && cartClick) {
      //   if (userInfo) {
      //     setInterval(history.push('/checkout/select-address'), 3000)
      //   } else {
      //     setInterval(history.push('/checkout/basic-information'), 3000)
      //   }
    }
  }, [cartSuccess, cartClick, order, orderSuccess, guestSuccess])
  function isEmail(email) {
    var emailFormat = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
    if (email !== '' && email && email.match(emailFormat)) {
      return true
    }

    return false
  }
  const handleNextStep = () => {
    setCurrentStep(currentStep + 1)
    localStorage.setItem('stepCompletion', currentStep)
    localStorage.setItem('completionDate', new Date().toISOString())
    setOnboardArray([...onboardArray, userInputs])
  }
  const changePrice = (value) => {
    setBoxType(value)
    if (value == 3) {
      setVprice('5499')
      product.salePrice = '5499'
    } else if (value == 2) {
      setVprice('2999')
      product.salePrice = '2999'
    } else {
      setVprice('999')
      product.salePrice = '999'
    }
    cookies.set('_cfx_price', product.salePrice, {
      path: '/',
      maxAge: 90000,
    })
    dispatch(
      addToCart(
        product._id,
        1,
        vId,
        product.isbn == 'BookscartBox1' ? null : inventoryId,
        value,
        true
      )
    )
    handleNextStep()
  }
  const handleBackStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1)
    }
  }

  const addFreeBookmarks = () => {
    cookies.set('_cfx_freeBookmarks', true, {
      path: '/',
      maxAge: 90000,
    })
    dispatch(
      addToCart(
        '6679aa0d2c7c67735604a80b',
        1,
        null,
        '6679a7962c7c677356f8bc7f',
        '',
        true
      )
    )
    setBookmarkMsgs('Added to Cart')
  }
  const addFreePoster = () => {
    dispatch(
      addToCart(
        '667595602c7c67735646bf39',
        1,
        null,
        '66759b322c7c67735673ed27',
        '',
        true
      )
    )
    setPosterMsgs('Added to Cart')
  }

  const updateVariantPrice = (ee) => {
    var e = document.getElementById('boxTypeSelect')
    SetVid(ee.target.id)
    setVprice(ee.target.value)
    product.salePrice = ee.target.value
    const img = new Image()
    img.src = '../../images/now.png'
    cookies.set('start_funnel', true, {
      path: '/',
      maxAge: 90000,
    })
    cookies.set('_cfx_variant', ee.target.name, {
      path: '/',
      maxAge: 90000,
    })
    handleNextStep()
  }
  const showPosterinfo = (e) => {
    e.preventDefault()
    setInfoShow(true)
  }
  const handlesetPhone = (value) => {
    if (value.length == 10) {
      setPhone(value)
      var phonenoExp = /^\d{10}$/
      if (phonenoExp.test(value)) {
        cookies.set('_cfx_phone', value, {
          path: '/',
          maxAge: 34550000,
        })
        dispatch(saveEmailToCart(value))
        handleNextStep()
      } else {
        setPhoneMessage('Enter Valid Phone Number')
      }
    } else {
      setPhone(value)
    }
  }
  const handlesetPincode = async (pincode) => {
    if (pincode.length == 6) {
      setPincode(pincode)
      cookies.set('_cfx_pin', pincode, {
        path: '/',
        maxAge: 34550000,
      })
      const { data } = await axios.get(`/api/products/zipcode/find/${pincode}`)
      if (data.length > 0) {
        cookies.set('_cfx_state', data[0].state, {
          path: '/',
          maxAge: 34550000,
        })

        cookies.set('_cfx_city', data[0].city, {
          path: '/',
          maxAge: 34550000,
        })
        setCity(data[0].city)
        setState(data[0].state)
      }
      dispatch(
        saveZipToCart(
          phone,
          pincode,
          data.length > 0 ? data[0].city : '',
          data.length > 0 ? data[0].state : ''
        )
      )
      handleNextStep()
      // if (userInfo) {
      //   setInterval(history.push('/checkout/select-address'), 3000)
      // } else {
      //   setInterval(history.push('/checkout'), 3000)
      // }
      // setRedirect(true)
    } else {
      setPincode(pincode)
    }
  }
  const gotoInfoPage = (e) => {
    e.preventDefault()
    if (userInfo && userInfo.name) {
      setInterval(history.push('/checkout/select-address'), 3000)
    } else {
      setInterval(history.push('/checkout'), 3000)
    }
  }

  const addpostersToCart = (e) => {
    dispatch(addToCart('667595602c7c67735646bf39', 1, null, null))
    if (userInfo && userInfo.name) {
      setInterval(history.push('/checkout/select-address'), 3000)
    } else {
      setInterval(history.push('/checkout'), 3000)
    }
    setRedirect(true)
  }

  const handleInput = (field, value) => {
    let newUserInputs = { ...userInputs }
    if (field === 'interest') {
      const newInterests = newUserInputs.interests.includes(value)
        ? newUserInputs.interests.filter((interest) => interest !== value)
        : [...newUserInputs.interests, value]
      newUserInputs.interests = newInterests
      cookies.set('_cfx_intrests', newInterests, {
        path: '/',
        maxAge: 90000,
      })
    } else {
      newUserInputs[field] = value
    }
    setUserInputs(newUserInputs)

    if (field === 'ageRange' || field === 'gender') {
      handleNextStep()
    }
  }
  const submitHandler2 = (e) => {
    e.preventDefault()
    var phonenoExp = /^\d{10}$/
    if (phonenoExp.test(phone)) {
      cookies.set('_cfx_phone', phone, {
        path: '/',
        maxAge: 34550000,
      })
      dispatch(saveEmailToCart(phone))
      handleNextStep()
    } else {
      setPhoneMessage('Enter Valid Phone Number')
    }
  }
  const redirectUser = (e) => {
    e.preventDefault()
    if (userInfo) {
      setInterval(history.push('/checkout/select-address'), 3000)
    } else {
      setInterval(history.push('/checkout'), 3000)
    }
    setRedirect(true)
  }
  const submitHandler3 = async (e) => {
    e.preventDefault()
    if (pincode.length === 6) {
      setPincode(pincode)
      cookies.set('_cfx_pin', pincode, {
        path: '/',
        maxAge: 34550000,
      })
      const { data } = await axios.get(`/api/products/zipcode/find/${pincode}`)
      if (data.length > 0) {
        cookies.set('_cfx_state', data[0].state, {
          path: '/',
          maxAge: 34550000,
        })

        cookies.set('_cfx_city', data[0].city, {
          path: '/',
          maxAge: 34550000,
        })
      }
      setCity(data[0].city)
      setState(data[0].state)
      handleNextStep()
      // if (userInfo) {
      //   setInterval(history.push('/checkout/select-address'), 3000)
      // } else {
      //   setInterval(history.push('/checkout'), 3000)
      // }
      // setRedirect(true)
    } else {
      setZipError('Enter 6 digit valid pincode')
    }
  }
  const submitEmailHandler = async (e) => {
    if (isEmail(email)) {
      setEmailMessage('')
      if (name.length > 4) {
        setNameMessage('')
        setEmail(email)
        setName(name)
        cookies.set('_cfx_name', name, {
          path: '/',
          maxAge: 34550000,
        })
        cookies.set('_cfx_email', email, {
          path: '/',
          maxAge: 34550000,
        })
        handleNextStep()
      } else {
        setNameMessage('Enter your name')
      }
    } else {
      setEmailMessage('Enter valid email')
    }
  }
  const submitAddressHandler = async (e) => {
    if (address.length > 5) {
      setAddress(address)
      setAddress1(address1)
      cookies.set('_cfx_address', address, {
        path: '/',
        maxAge: 34550000,
      })
      cookies.set('_cfx_address1', address1, {
        path: '/',
        maxAge: 34550000,
      })
      // handleNextStep()
      if (userInfo) {
        setInterval(history.push('/checkout/select-address'), 3000)
      } else {
        setInterval(history.push('/checkout'), 3000)
      }
    } else {
      setAddressMessage('Enter valid address')
    }
  }

  const submitCodHandler = async (e) => {
    setShowCOD(true)
  }

  const submitPmHandler = async (e) => {
    e.preventDefault()
    setPayClick(true)
    dispatch(
      createGuestUserAddress({
        name,
        address,
        address2: address1,
        city,
        postalCode: pincode,
        state,
        country: 'IN',
        phone,
        email,
        cartId: cookies.get('_cid'),
        pmethod: e.target.value,
      })
    )
  }
  const checkout22 = async (e) => {
    if (userInfo) {
      setInterval(history.push('/checkout/select-address'), 3000)
    } else {
      setInterval(history.push('/checkout'), 3000)
    }
    setRedirect(true)
  }
  const resetFormSelection = async (e) => {
    cookies.remove('start_funnel', {
      path: '/',
    })
    window.location.reload(false)
  }

  const progress = Math.round((currentStep / 9) * 100) // Total steps are now 5

  return (
    <Container
      className='mt-1'
      style={{
        padding: '25px',
        maxWidth: '650px',
      }}
    >
      <div className='text-center'>
        <Link to='/'>
          <img
            src='../../images/logo.png'
            style={{ width: '140px' }}
            alt='nexnuggets logo'
          />
        </Link>
        <br />
        <div
          style={{ fontSize: '24px', marginBottom: '12px', fontWeight: 'bold' }}
        >
          Build Your Own Library
        </div>
      </div>
      {show ? (
        <>
          <ProgressBar
            now={progress}
            label={`${progress}%`}
            style={{ height: '22px' }}
          />
          <div className='mt-1'>
            <h2
              className='text-center'
              style={{
                fontWeight: 'bold',
                fontSize: '22px',
                marginTop: '10px',
              }}
            >{`${
              [
                // 'What is the age of the reader?',
                // 'Please select gender',
                'Select your library type',
                'Select library size',
                'Areas of interest to improve',
                'READ LEAD SUCCEED',
                'Enter your phone number',
                'Select free items',
                'Enter delivery pincode',
                'Enter your email',
                'Enter delivery Address',
                'Confirm Order',
              ][currentStep - 1]
            }`}</h2>
            {/* {currentStep === 1 &&
          ['5-12', '13-19', '18-35', '36-45', '46+'].map((ageRange) => (
            <Row key={ageRange} className='mt-3'>
              <Button
                variant='outline-primary'
                onClick={() => handleInput('ageRange', ageRange)}
              >
                {ageRange}
              </Button>
            </Row>
          ))}*/}
            {/* {currentStep === 1 &&
          ['👩 Female/Girl', '👨 Male/Boy', '🧑 Other'].map((gender) => (
            <Row key={gender} className='mt-3'>
              <Button
                variant='outline-primary'
                onClick={() => handleInput('gender', gender)}
              >
                {gender}
              </Button>
            </Row>
          ))} */}
            {currentStep === 1 && (
              <>
                {/* <Row className='text-center'>
                  {product.boxType &&
                    product.boxType.map((x) => (
                      <Col xs={6} md={4} key={x._id}>
                        <Button
                          variant='outline-primary'
                          key={x._id}
                          id={x._id}
                          label={x.name}
                          data-id={x._id}
                          name={x.name}
                          value={x.price}
                          className='funnel-button'
                          onClick={(e) => updateVariantPrice(e)}
                        >
                          {x.name}
                        </Button>
                      </Col>
                    ))}
                </Row> */}
                {/* <Row className='text-center'>
                  <h2 className='box-title'>Suprise Box</h2>
                  <p className='box-prices-sec'>&#8377;999/-</p>
                </Row> */}
                <Row className='text-center'>
                  <Col xs={6} md={6} key='63418817bf16c964e441bd37'>
                    <Button
                      variant='outline-primary'
                      key='63418817bf16c964e441bd37'
                      id='63418817bf16c964e441bd37'
                      data-id='63418817bf16c964e441bd37'
                      value='999'
                      name='Kids(Age 5-8)'
                      className='funnel-button'
                      onClick={(e) => updateVariantPrice(e)}
                    >
                      🎨 Kids (Age 5-8)
                    </Button>
                  </Col>
                  <Col xs={6} md={6} key='63418817bf16c964e441bd33'>
                    <Button
                      variant='outline-primary'
                      key='63418817bf16c964e441bd33'
                      id='63418817bf16c964e441bd33'
                      data-id='63418817bf16c964e441bd33'
                      value='999'
                      name='Teen(Age 13-19)'
                      className='funnel-button'
                      onClick={(e) => updateVariantPrice(e)}
                    >
                      🎒 Teen(Age 13-19)
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} md={6} key='63409695f3b59b2afc664000'>
                    <Button
                      variant='outline-primary'
                      key='63409695f3b59b2afc664000'
                      id='63409695f3b59b2afc664000'
                      data-id='63409695f3b59b2afc664000'
                      value='999'
                      name='Mixed Fiction'
                      className='funnel-button'
                      onClick={(e) => updateVariantPrice(e)}
                    >
                      🌈 Mixed Fictions
                    </Button>
                  </Col>
                  <Col xs={6} md={6} key='63418817bf16c964e441bd38'>
                    <Button
                      variant='outline-primary'
                      key='63418817bf16c964e441bd39'
                      id='63418817bf16c964e441bd39'
                      data-id='63418817bf16c964e441bd39'
                      value='1122'
                      className='funnel-button'
                      name='Crime & Mystery'
                      onClick={(e) => updateVariantPrice(e)}
                    >
                      🔍 Crime & Mystery
                    </Button>
                  </Col>
                </Row>
                {/* <Row className='text-center'>
                  <h2 className='box-title'>Premium Boxes</h2>
                  <p className='box-prices-sec'>
                    &#8377;999 + &#8377;123 = &#8377;1,122/-
                  </p>
                </Row> */}
                <Row className='text-center'>
                  <Col xs={6} md={6} key='63418817bf16c964e441bd38'>
                    <Button
                      variant='outline-primary'
                      key='63418817bf16c964e441bd38'
                      id='63418817bf16c964e441bd38'
                      data-id='63418817bf16c964e441bd38'
                      value='1122'
                      name='Adult & Romantic'
                      className='funnel-button'
                      onClick={(e) => updateVariantPrice(e)}
                    >
                      💕 Adult & Romantic
                    </Button>
                  </Col>
                  <Col xs={6} md={6} key='63418817bf16c964e441bd39'>
                    <Button
                      variant='outline-primary'
                      key='63418817bf16c964e441bd41'
                      id='63418817bf16c964e441bd41'
                      data-id='63418817bf16c964e441bd41'
                      value='1122'
                      className='funnel-button'
                      name='Classic & Literature'
                      onClick={(e) => updateVariantPrice(e)}
                    >
                      🖋️ Classic & Literature
                    </Button>
                  </Col>
                </Row>
                <Row className='text-center'>
                  <Col xs={6} md={6} key='63418817bf16c964e441bd41'>
                    <Button
                      variant='outline-primary'
                      key='63418817bf16c964e441bd38'
                      id='63418817bf16c964e441bd38'
                      data-id='63418817bf16c964e441bd38'
                      value='1122'
                      name='Womens & Contemporary'
                      className='funnel-button'
                      onClick={(e) => updateVariantPrice(e)}
                    >
                      🌺 Women's & Contemporary
                    </Button>
                  </Col>
                  <Col xs={6} md={6} key='63418817bf16c964e441bd40'>
                    <Button
                      variant='outline-primary'
                      key='63418817bf16c964e441bd40'
                      id='63418817bf16c964e441bd40'
                      data-id='63418817bf16c964e441bd40'
                      value='1122'
                      className='funnel-button'
                      name='Best Selling & 99bookscart'
                      onClick={(e) => updateVariantPrice(e)}
                    >
                      🌟 Best Selling & 99bookscart
                    </Button>
                  </Col>
                </Row>
                <Row className='text-center'>
                  <Col xs={6} md={6} key='63418817bf16c964e441bd43'>
                    <Button
                      variant='outline-primary'
                      key='63418817bf16c964e441bd43'
                      id='63418817bf16c964e441bd43'
                      data-id='63418817bf16c964e441bd43'
                      value='1122'
                      className='funnel-button'
                      name='Science-Fiction & Fantasy'
                      onClick={(e) => updateVariantPrice(e)}
                    >
                      🚀 Science-Fiction & Fantasy
                    </Button>
                  </Col>
                  <Col xs={6} md={6} key='63418817bf16c964e441bd3f'>
                    <Button
                      variant='outline-primary'
                      key='63418817bf16c964e441bd3f'
                      id='63418817bf16c964e441bd3f'
                      data-id='63418817bf16c964e441bd3f'
                      value='1122'
                      className='funnel-button'
                      name='Non Fiction & Biography'
                      onClick={(e) => updateVariantPrice(e)}
                    >
                      🌍 Historical Fictions
                    </Button>
                  </Col>
                </Row>
                {/* <Row className='text-center'>
                  <Col xs={6} md={6} key='63418817bf16c964e441bd47'>
                    <Button
                      variant='outline-primary'
                      key='63418817bf16c964e441bd47'
                      id='63418817bf16c964e441bd47'
                      data-id='63418817bf16c964e441bd47'
                      value='1122'
                      className='funnel-button'
                      name='Non Fiction & Biography Library'
                      onClick={(e) => updateVariantPrice(e)}
                    >
                      🌍 Non Fiction & Biography Library
                    </Button>
                  </Col>
                  <Col xs={6} md={6} key='63418817bf16c964e441bd45'>
                    <Button
                      variant='outline-primary'
                      key='63418817bf16c964e441bd45'
                      id='63418817bf16c964e441bd45'
                      data-id='63418817bf16c964e441bd45'
                      value='1122'
                      name='Thrill & Adventures Library'
                      className='funnel-button'
                      onClick={(e) => updateVariantPrice(e)}
                    >
                      ⚡ Thrill & Adventures Library
                    </Button>
                  </Col>
                </Row> */}
              </>
            )}
            {currentStep === 2 && (
              <div style={{ textAlign: 'center' }}>
                <Row>
                  <Row>
                    <Button
                      variant='outline-primary'
                      key={1}
                      id={1}
                      value={1}
                      className='funnel-button-size'
                      onClick={(e) => changePrice(e.target.value)}
                    >
                      15 Books for &#8377;999.00
                    </Button>
                  </Row>
                  <Row>
                    <Button
                      variant='outline-primary'
                      key={2}
                      id={2}
                      value={2}
                      className='funnel-button-size'
                      onClick={(e) => changePrice(e.target.value)}
                    >
                      50 Books for &#8377;2,999.00
                    </Button>
                  </Row>
                  <Row>
                    <Button
                      variant='outline-primary'
                      key={3}
                      id={3}
                      value={3}
                      className='funnel-button-size'
                      onClick={(e) => changePrice(e.target.value)}
                    >
                      100 Books for &#8377;5,499.00
                    </Button>
                  </Row>
                </Row>
              </div>
            )}
            {currentStep === 3 && (
              <div style={{ textAlign: 'center' }}>
                <small style={{ color: '#E74C3C' }}>
                  Select atleast 2 options
                </small>
                <br />
                {[
                  'Enhance Empathy',
                  'Boost Creativity',
                  'Improve Language Skills',
                  'Stress Reduction',
                  'Problem Solving',
                  'Enhance Imagination',
                  'Emotional Intelligence',
                  'Entertainment and Joy',
                ].map((interest) => (
                  <Button
                    key={interest}
                    variant={
                      userInputs.interests.includes(interest)
                        ? 'primary'
                        : 'outline-primary'
                    }
                    onClick={() => handleInput('interest', interest)}
                    className='mt-1 funnel-button-size'
                    style={{ marginRight: '6px' }}
                  >
                    {interest}
                  </Button>
                ))}
                <Row className='mt-3'>
                  {userInputs.interests.length >= 2 && (
                    <Button variant='primary' onClick={handleNextStep}>
                      Next
                    </Button>
                  )}
                </Row>
              </div>
            )}
            {currentStep === 4 && (
              <Row style={{ textAlign: 'center' }}>
                <Row style={{ textAlign: 'center', padding: '15px' }}>
                  <LazyLoadImage
                    src='../../images/now.png'
                    width={'100%'}
                    style={{
                      maxWidth: '180px',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  />
                  <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                    If you read 15 books in a year then you are amoung top 3%
                    readers in India.
                  </div>
                </Row>
                <Row>
                  <Button
                    variant='outline-primary'
                    className='mt-2'
                    style={{ marginLeft: '10px' }}
                    onClick={handleNextStep}
                  >
                    &rarr; Continue
                  </Button>
                </Row>
              </Row>
            )}
            {currentStep === 5 && (
              <Row style={{ textAlign: 'center' }}>
                <Row>
                  <div style={{ padding: '20px' }}>
                    <Form.Group>
                      <div className='error-text'>
                        {phoneMessage && phoneMessage}
                      </div>
                      <br />
                      <TextField
                        id='outlined-basic'
                        label='Phone Number'
                        variant='outlined'
                        required
                        type='Number'
                        autoComplete='off'
                        value={phone}
                        onChange={(e) => handlesetPhone(e.target.value)}
                        style={{ width: '100%', marginBottom: '5px' }}
                      />
                    </Form.Group>
                  </div>
                </Row>
                <Row>
                  <Button
                    variant='outline-primary'
                    className='mt-5'
                    style={{ marginLeft: '10px' }}
                    onClick={submitHandler2}
                  >
                    &rarr; Continue
                  </Button>
                </Row>
              </Row>
            )}
            {currentStep === 6 && (
              <Row style={{ textAlign: 'center' }}>
                <Row>
                  <div style={{ padding: '20px' }}>
                    <Row className='free-pro'>
                      <Col
                        sm={12}
                        md={6}
                        style={{ fontWeight: 'bold', fontSize: '17px' }}
                      >
                        10 FREE Premium BookMarks{' '}
                      </Col>
                      <Col
                        sm={12}
                        md={2}
                        style={{ fontSize: '18px', fontWeight: 'bold' }}
                      >
                        <strike>&#8377;99.00/-</strike> &#8377;0.00/-
                      </Col>
                      <Col sm={12} md={4}>
                        <Button
                          variant='outline-primary'
                          style={{ marginLeft: '10px' }}
                          onClick={addFreeBookmarks}
                        >
                          <FaCartPlus style={{ color: '#000' }}></FaCartPlus>{' '}
                          {bookmarkMsgs}
                        </Button>
                      </Col>
                    </Row>
                    <br />
                    <Row className='free-pro'>
                      <Col
                        sm={12}
                        md={6}
                        style={{ fontWeight: 'bold', fontSize: '17px' }}
                      >
                        30 Premium Motivational Poster(4"X6"){' '}
                      </Col>
                      <Col
                        sm={12}
                        md={2}
                        style={{ fontSize: '18px', fontWeight: 'bold' }}
                      >
                        &#8377;249.00/-
                      </Col>
                      <Col sm={12} md={4}>
                        <Button
                          variant='outline-primary'
                          style={{ marginLeft: '10px' }}
                          onClick={addFreePoster}
                        >
                          <FaCartPlus style={{ color: '#000' }}></FaCartPlus>{' '}
                          {posterMsgs}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Row>
                <Row>
                  <Button
                    variant='outline-primary'
                    className='mt-3'
                    style={{ marginLeft: '10px' }}
                    onClick={handleNextStep}
                  >
                    &rarr; Continue
                  </Button>
                </Row>
              </Row>
            )}
            {/* {currentStep === 5 && (
          <Row style={{ textAlign: 'center' }}>
            <Row>
              <div style={{ padding: '20px' }}>
                <LazyLoadImage
                  src='../../images/posterbanner.jpg'
                  width='100%'
                  style={{ maxwidth: '300px' }}
                />
                <div
                  style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    marginTop: '8px',
                  }}
                >
                  30 Motivational Posters for ₹499/-
                </div>
                <div>FREE SHIPPING</div>
              </div>
            </Row>
            <Row>
              <Button
                variant='outline-primary'
                className='mt-3'
                style={{ marginLeft: '10px' }}
                onClick={showPosterinfo}
              >
                &darr; Learn more
              </Button>
            </Row>
            {infoShow && (
              <Row>
                <div style={{ padding: '12px' }}>
                  <div>
                    <LazyLoadImage
                      src='../../images/posters.jpg'
                      alt='motivational posters'
                      width={'100%'}
                      style={{ maxWidth: '300px' }}
                    />
                    <div
                      style={{
                        fontSize: '26px',
                        fontWeight: 'bold',
                        marginTop: '10px',
                      }}
                    >
                      Transform Your Walls, Transform Your Mindset
                    </div>
                    <p style={{ marginTop: '6px', fontSize: '16px' }}>
                      Elevate your space with our collection of 30 high-quality
                      motivational posters. Designed to inspire and captivate,
                      this affordable set adds a perfect touch of inspiration to
                      any room. Transform your environment today with powerful
                      quotes and stunning designs with size 4"X6" inch
                    </p>
                    <p>
                      <span
                        style={{
                          fontSize: '30px',
                          fontWeight: 'bold',
                          color: '#005D6E ',
                        }}
                      >
                        ₹499/-
                      </span>
                      <br />
                      <strike style={{ color: '#FA4741', fontSize: '20px' }}>
                        ₹3,999
                      </strike>
                    </p>
                    <div
                      style={{
                        fontSize: '15px',
                        fontWeight: 'bold',
                      }}
                    >
                      30 High Quality Motivational Posters
                    </div>
                    <Button
                      variant='primary'
                      className='mt-3'
                      style={{ marginLeft: '12px' }}
                      onClick={addpostersToCart}
                    >
                      <FaCartPlus style={{ color: '#000' }}></FaCartPlus> Add to
                      cart
                    </Button>
                  </div>
                </div>
              </Row>
            )}
            <div>
              <Button
                variant='outline-primary'
                className='mt-3'
                onClick={gotoInfoPage}
              >
                &rarr; Not intrested
              </Button>
            </div>
          </Row>
        )} */}
            {currentStep === 7 && (
              <Row style={{ textAlign: 'center' }}>
                <Row>
                  <div style={{ padding: '20px' }}>
                    <Form.Group>
                      <div className='error-text'>{zipError && zipError}</div>
                      <br />
                      <TextField
                        id='outlined-basic'
                        label='Zip Code'
                        variant='outlined'
                        required
                        type='Number'
                        autoComplete='off'
                        value={pincode}
                        pattern='\d{6}'
                        onChange={(e) => handlesetPincode(e.target.value)}
                        style={{ width: '100%', marginBottom: '5px' }}
                      />
                    </Form.Group>
                  </div>
                </Row>
                <Row>
                  <Button
                    variant='outline-primary'
                    className='mt-5'
                    style={{ marginLeft: '10px' }}
                    onClick={submitHandler3}
                  >
                    &rarr; Continue
                  </Button>
                </Row>
              </Row>
            )}
            {currentStep === 8 && (
              <Row style={{ textAlign: 'center' }}>
                <Row>
                  <div style={{ padding: '20px' }}>
                    <Form.Group>
                      <div className='error-text'>
                        {emailMessage && emailMessage}
                      </div>
                      <br />
                      <TextField
                        id='outlined-basic'
                        label='Email'
                        variant='outlined'
                        required
                        type='email'
                        autoComplete='off'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={{ width: '100%', marginBottom: '5px' }}
                      />
                    </Form.Group>
                    <Form.Group>
                      <div className='error-text'>
                        {nameMessage && nameMessage}
                      </div>
                      <br />
                      <TextField
                        id='outlined-basic'
                        label='Full Name'
                        variant='outlined'
                        required
                        type='Text'
                        autoComplete='off'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        style={{ width: '100%', marginBottom: '5px' }}
                      />
                    </Form.Group>
                  </div>
                </Row>
                <Row>
                  <Button
                    variant='outline-primary'
                    className='mt-5'
                    style={{ marginLeft: '10px' }}
                    onClick={submitEmailHandler}
                  >
                    &rarr; Continue
                  </Button>
                </Row>
              </Row>
            )}
            {currentStep === 9 && (
              <Row style={{ textAlign: 'center' }}>
                <Row>
                  <div style={{ padding: '20px' }}>
                    <strong>
                      {' '}
                      Expected Delivery <strong>
                        {city && `to ${city}`}
                      </strong>{' '}
                      By {days[deliveryDate.getDay()]},{deliveryDate.getDate()}{' '}
                      {months[deliveryDate.getMonth()]}, 2024
                    </strong>
                    <Container>
                      <Row>
                        <Col xs={6}>
                          <LazyLoadImage
                            src='../images/del.png'
                            alt='delhivery logo'
                            width={'80px'}
                          />
                        </Col>
                        <Col xs={6}>
                          <LazyLoadImage
                            src='../images/amaz.png'
                            alt='delhivery logo'
                            width={'100px'}
                          />
                        </Col>
                      </Row>
                    </Container>
                    <Form.Group>
                      <div className='error-text'>
                        {addressMessage && addressMessage}
                      </div>
                      <br />
                      <TextField
                        id='outlined-basic'
                        label='Address'
                        variant='outlined'
                        required
                        type='text'
                        autoComplete='off'
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        style={{ width: '100%', marginBottom: '5px' }}
                      />
                    </Form.Group>
                    <Form.Group>
                      <TextField
                        id='outlined-basic'
                        label='Address 2 (Optional)'
                        variant='outlined'
                        type='text'
                        autoComplete='off'
                        value={address1}
                        onChange={(e) => setAddress1(e.target.value)}
                        style={{
                          width: '100%',
                          marginBottom: '5px',
                          marginTop: '8px',
                        }}
                      />
                    </Form.Group>
                  </div>
                  <div style={{ fontSize: '18px', fontWeight: 'bold' }}>
                    {city}, {state},{pincode}
                  </div>
                </Row>
                <Row>
                  <Button
                    variant='outline-primary'
                    className='mt-5'
                    style={{ marginLeft: '10px' }}
                    onClick={submitAddressHandler}
                  >
                    &rarr; Continue
                  </Button>
                </Row>
              </Row>
            )}
            {/* {currentStep === 10 && (
              <Row style={{ textAlign: 'center' }}>
                <Row>
                  <div style={{ padding: '6px' }}>
                    <Form.Group>
                      <div
                        className='order-sum1'
                        style={{ marginBottom: '10px', marginLeft: '20px' }}
                      >
                        <Row>
                          <Col xs={7}>Cart Total</Col>

                          <Col xs={5}>
                            &#8377;
                            {cart.cartPrice &&
                              cart.cartPrice.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={7}> Delivery Charges:</Col>

                          <Col xs={5}>
                            &#8377;
                            {cart.shippingPrice &&
                              addDecimals(
                                cart.shippingPrice.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              )}
                          </Col>
                        </Row>

                        {cart.discountPrice > 0 && (
                          <>
                            <Row
                              style={{
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: '#fa4741',
                              }}
                            >
                              <Col xs={7}>Discount</Col>

                              <Col xs={5}>
                                -₹{' '}
                                {addDecimals(
                                  cart.discountPrice.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                )}
                              </Col>
                            </Row>
                          </>
                        )}
                        <Row>
                          <Col xs={7}> Payable Total</Col>

                          <Col xs={5} style={{ fontSize: '19px' }}>
                            &#8377;{' '}
                            {cart.totalPrice &&
                              cart.totalPrice.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                          </Col>
                        </Row>
                      </div>
                      <Row>
                        <Typography
                          variant='caption'
                          className='text-center'
                          style={{ color: '#333' }}
                        >
                          Pay using UPI, Card or Net Banking etc. (FREE &
                          Secure)
                        </Typography>
                        <Button
                          className='ldp-variant2'
                          id='Online'
                          name='paymentMethod'
                          value='online'
                          checked='checked'
                          onClick={(e) => submitPmHandler(e)}
                        >
                          (₹0) Pay Online
                        </Button>
                        <div className='text-center'>
                          <LazyLoadImage
                            src='../images/razorpay.png'
                            alt='razorpay'
                            width={'80px'}
                          />
                          <LazyLoadImage
                            src='../images/norton.png'
                            alt='shiprocket logo'
                            width={'80px'}
                          />
                        </div>
                      </Row>

                      <Row>
                        <Typography
                          variant='caption'
                          className='text-center'
                          style={{ color: '#333' }}
                        >
                          Customer needs to confirm all COD order through OTP
                        </Typography>
                        <Button
                          className='ldp-variant2'
                          id='COD'
                          name='paymentMethod'
                          value='cod'
                          onClick={(e) => submitPmHandler(e)}
                        >
                          (+₹99) Pay On Delivery
                        </Button>
                      </Row>
                    </Form.Group>
                  </div>
                </Row>
              </Row>
            )} */}
            {currentStep > 1 && (
              <Row>
                <Button
                  variant='outline-primary'
                  className='mt-5'
                  onClick={handleBackStep}
                >
                  &larr; Back
                </Button>
              </Row>
            )}
          </div>
        </>
      ) : (
        <>
          <Row>
            <h3>Surprise Box Details</h3>
            <p>
              <strong> Library Type :</strong> {cookies.get('_cfx_variant')}
              <br />
              {cookies.get('_cfx_price') && (
                <>
                  <strong>Library Size: </strong>
                  {cookies.get('_cfx_price').toLocaleString()}
                  <br />
                </>
              )}
              {cookies.get('_cfx_intrests') && (
                <>
                  <strong>Intrests: </strong>
                  {cookies.get('_cfx_intrests').toString()}
                  <br />
                </>
              )}
              {cookies.get('_cfx_phone') && (
                <>
                  <strong>Phone: </strong>
                  {cookies.get('_cfx_phone')}
                  <br />
                </>
              )}
              {cookies.get('_cfx_pin') && (
                <>
                  <strong> Pin: </strong>
                  {cookies.get('_cfx_pin')}
                </>
              )}
            </p>
            <Button onClick={checkout22}>Checkout</Button>
          </Row>
          <Row>
            <Button
              variant='outline-primary'
              style={{ marginTop: '18px' }}
              onClick={resetFormSelection}
            >
              Reset Selections
            </Button>
          </Row>
        </>
      )}
      <Modal show={showCOD} backdrop='static'>
        <Modal.Body>
          <Container style={{ padding: '35px' }}>
            <h5>Pay On Delivery Order</h5>
            <p>
              Cart total: ₹
              {cart && cart.totalPrice && cart.totalPrice.toLocaleString()}
              <br />
              Pay On Delivery fees: ₹{99}
              <br />
              Order total: ₹
              <span style={{ fontSize: '17px', fontWeight: 'bold' }}>
                {cart &&
                  cart.totalPrice &&
                  (cart.totalPrice + 99.0).toLocaleString()}
              </span>
            </p>
            <Button
              className='ldp-variant2'
              id='COD'
              name='paymentMethod'
              value='cod'
              onClick={(e) => submitPmHandler(e)}
            >
              Place Order
            </Button>
            <br />
            <div onClick={() => setShowCOD(false)}>Cancel & pay online</div>
          </Container>
        </Modal.Body>
      </Modal>
    </Container>
  )
}

export default StepFunnel
