import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  ListGroupItem,
  Container,
  Form,
  FormGroup,
  Modal,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { getOrderDetails, deliverOrder } from '../actions/orderActions'
import {
  ORDER_CREATE_RESET,
  ORDER_DELEVERED_RESET,
} from '../constants/orderConstants'
import { CART_RESET } from '../constants/cartConstants'
import { FaTruckMoving } from 'react-icons/fa'
import { createNewPassword, otpVerify } from '../actions/userActions'
import { TextField } from '@mui/material'

const OrderConfirmScreen = ({ history, match }) => {
  const orderId = match.params.id

  const dispatch = useDispatch()
  const [otpMessage, setOtpMessage] = useState('')
  const [password, setPassword] = useState('')
  const orderDetails = useSelector((state) => state.orderDetails)
  const { order, coin, loading, error, succees } = orderDetails

  const orderPayStatus = useSelector((state) => state.orderPayStatus)
  const { loading: orderPayStatusLoading } = orderPayStatus

  const otpVerifyRes = useSelector((state) => state.otpVerify)
  const { loading: otpLoading, success: otpSuccess, result } = otpVerifyRes

  const createPassword = useSelector((state) => state.createPassword)
  const {
    resetResult,
    loading: resetLoading,
    error: resetError,
    succees: resetSuccess,
  } = createPassword

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const [tLink, setTLink] = useState('')

  const [show, setShow] = useState(
    localStorage.getItem('isPassword') === 'true' ? false : true
  )
  const [otp, setOtp] = useState(false)
  const closeModal = () => {
    setShow(false)
    localStorage.setItem('isPassword', false)
  }
  const updatePassword = () => {
    dispatch(createNewPassword(password))
    localStorage.setItem('isPassword', true)
    setInterval(function () {
      setShow(false)
    }, 2000)
  }
  dispatch({ type: ORDER_CREATE_RESET })
  dispatch({ type: CART_RESET })
  const otpHandler = (e) => {
    e.preventDefault()
    dispatch(
      otpVerify({
        phone: order.shippingAddress.phone,
        otp: otp,
        order: orderId,
      })
    )
  }
  useEffect(() => {
    if (userInfo) {
      if (!order || order._id !== orderId || orderPayStatusLoading) {
        dispatch(getOrderDetails(orderId))
        dispatch({ type: ORDER_DELEVERED_RESET })
      }
      if (!loading) {
        const addDecimals = (num) => {
          return (Math.round(num * 100) / 100).toFixed(2)
        }
        order.itemsPrice = addDecimals(
          order.orderItems.reduce(
            (acc, item) => acc + item.salePrice * item.qty,
            0
          )
        )
      }

      if (otpSuccess) {
        setOtp('')
        if (result.result) {
          setOtpMessage('Order has been Confimred')
          setInterval(history.push(`/order/${order._id}`), 2000)
        } else {
          setOtpMessage('OTP is not verified')
        }
      }
    } else {
      history.push('/login')
    }
  }, [
    dispatch,
    order,
    orderId,
    orderPayStatusLoading,
    succees,
    otpSuccess,
    result,
  ])
  return (
    <Container>
      {loading ? (
        <Loader></Loader>
      ) : error ? (
        <Message>{error}</Message>
      ) : (
        <Container>
          <Row style={{ marginBottom: '60px' }}>
            <Col md={8}>
              <ListGroup variant='flush'>
                <ListGroup.Item>
                  <div style={{ fontSize: '28px' }}>
                    <i className='far fa-check-circle green'></i> Thank you for
                    Placing the Order
                  </div>
                  <div>
                    Congratulations You have earned {coin && coin.coins} Points
                  </div>
                  {order && order.codPrice > 0 && !order.isConfirmed && (
                    <div className='otp-handler'>
                      <h2>
                        Enter OTP Sent to email {order.shippingAddress.email} &
                        text {order.shippingAddress.phone}
                      </h2>
                      <small>All COD Orders needs to be Confirmed by OTP</small>
                      <Form onSubmit={otpHandler}>
                        <FormGroup controlId='otp'>
                          {otpMessage && (
                            <span className='red-text'>{otpMessage}</span>
                          )}
                          <Form.Control
                            type='text'
                            placeholder='OTP'
                            onChange={(e) => setOtp(e.target.value)}
                            required
                          ></Form.Control>
                          <div>
                            <Button
                              style={{ float: 'right' }}
                              type='submit'
                              variant='primary'
                              className='text-right'
                            >
                              Verify OTP
                            </Button>
                          </div>
                        </FormGroup>
                      </Form>
                    </div>
                  )}

                  <div className='checkout-shipping-address'>
                    <h6>Shipping Address</h6>
                    <strong>Name:</strong>
                    {order.user ? order.user.name : order.shippingAddress.name}
                    <br />
                    <strong> Email:</strong>
                    {order.user
                      ? order.user.email
                      : order.shippingAddress.email}
                    <p>
                      {order.shippingAddress.name}
                      <br />
                      {order.shippingAddress.address},
                      {order.shippingAddress.address2},<br />
                      {order.shippingAddress.city},{order.shippingAddress.state}
                      ,{order.shippingAddress.postalCode}
                      <br />
                      {order.shippingAddress.phone}{' '}
                    </p>
                  </div>
                </ListGroup.Item>
                {/* <ListGroup.Item>
                            <Row>
                                <Col>
                                    <h4>Payment</h4>
                                    <p>
                                        <strong>
                                        <span className='capitalize-text'>{order.paymentMethod}</span>({order.isPaid?<small className='text-muted'>Paid on:{order.paidAt.substring(0,10)}</small>:
                                    <small className='text-muted'>Not Paid</small>})</strong>
                                    </p>
                                    
                                </Col>
                                <Col>
                                <h4>Shipping</h4>
                                    <p>
                                        <strong>
                                        <span className='capitalize-text'>{order.shippingMethod}</span></strong>
                                    </p>
                                </Col>
                            </Row>
                            
                        </ListGroup.Item> */}
                <ListGroup.Item>
                  <h6>Order Details</h6>
                  {order.orderItems.length == 0 ? (
                    <Message>Order is Empty</Message>
                  ) : (
                    <ListGroup variant='flush'>
                      {order.orderItems.map((item, index) => (
                        <ListGroup.Item key={item.product}>
                          <Row>
                            <Col md={2} xs={2}>
                              <Image
                                src={item.image}
                                alt={item.name}
                                fluid
                                rounded
                                className='cartImage'
                                style={{ padding: '6px' }}
                              />
                            </Col>
                            <Col
                              md={8}
                              xs={8}
                              style={{ padding: '6px', paddingLeft: '16px' }}
                            >
                              <Link
                                to={`/book/${item.isbn}/${convertToSlug(
                                  item.name
                                )}`}
                              >
                                {item.name}{' '}
                                {item.variantName &&
                                  '(' + item.variantName + ')'}
                              </Link>
                              <br />
                              &#8377;
                              {Number.parseFloat(item.salePrice).toFixed(2)}
                              <br />
                              {item.sellerName && (
                                <small className='text-muted'>
                                  Sold By: {item.sellerName}
                                </small>
                              )}
                            </Col>
                            {/* <Col md={2} xs={2}>
                                            &#8377;{Number.parseFloat(item.salePrice).toFixed(2)}
                                            </Col> */}
                            {/* <Col md={2} xs={2}>
                                            <Form.Control as='select' value={item.qty} onChange={(e)=>
                                                    dispatch(addToCart(item.product,Number(e.target.value)))}>
                                                {[...Array(item.countInStock).keys()].map((x)=>(
                                                    <option key={x+1} value={x+1}>{x+1}</option>
                                                ))}
                                            </Form.Control>
                                            </Col> */}
                          </Row>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  )}
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={4} style={{ padding: '6px' }}>
              <Card>
                <ListGroup variant='flash'>
                  <ListGroupItem>
                    <h6> Order Summary</h6>
                  </ListGroupItem>
                  <ListGroupItem>
                    <Row>
                      <Col>Items Total</Col>
                      <Col className='productPrice'>
                        &#8377;{Number.parseFloat(order.itemPrice).toFixed(2)}
                      </Col>
                    </Row>
                  </ListGroupItem>
                  <ListGroupItem>
                    <Row>
                      <Col>Shipping</Col>
                      <Col className='productPrice'>
                        &#8377;
                        {Number.parseFloat(order.shippingPrice).toFixed(2)}
                      </Col>
                    </Row>
                  </ListGroupItem>
                  {order.codPrice > 0 && (
                    <ListGroupItem>
                      <Row>
                        <Col>COD Charges</Col>
                        <Col className='productPrice'>
                          &#8377;{Number.parseFloat(order.codPrice).toFixed(2)}
                        </Col>
                      </Row>
                    </ListGroupItem>
                  )}
                  {order.taxPrice > 0 && (
                    <ListGroupItem>
                      <Row>
                        <Col>Tax</Col>
                        <Col className='productPrice'>
                          &#8377;{Number.parseFloat(order.taxPrice).toFixed(2)}
                        </Col>
                      </Row>
                    </ListGroupItem>
                  )}
                  {order.discountPrice > 0 && (
                    <ListGroupItem>
                      <Row>
                        <Col>Discount</Col>
                        <Col className='productPrice'>
                          -&#8377;
                          {Number.parseFloat(order.discountPrice).toFixed(2)}
                        </Col>
                      </Row>
                    </ListGroupItem>
                  )}
                  <ListGroupItem>
                    <Row>
                      <Col>Total</Col>
                      <Col className='productPrice'>
                        &#8377;{Number.parseFloat(order.totalPrice).toFixed(2)}
                      </Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
      
    </Container>
  )
}
function convertToSlug(Text) {
  return Text.toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}
export default OrderConfirmScreen
